import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    Col,
    FormText,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    Row,
    Spinner
} from 'reactstrap';
import {
    arrayToDictionary,
    changeFormatOfDateString,
    findIndex,
    getChildAccountID, getDateString,
    getDeliveryAddressString,
    getSorted,
    handleErrorMessage
} from '../../../../../services/CommonService';
import CustomerService from '../../../../../services/CustomerService';
import UserService from '../../../../../services/UserService';
import salesOrderProductBuilderV1Service from '../../../../../services/sales/SalesOrderProductBuilderV1Service';
import freightService from '../../../../../services/FreightService';
import addressService from '../../../../../services/AddressService';
import OrderService from '../../../../../services/OrderService';
import productService from '../../../../../services/product/ProductService';
import packagingHandlingChargesService from '../../../../../services/shipment/PackagingHandlingChargesService';
import CustomerDashboardQuickLinks from '../../../../../components/CustomerDashboard/CustomerDashboardQuickLinks';
import SalesOrderProductBuilderNavigationTab from './SalesOrderProductBuilderNavigationTab';
import DebtorAddressModal from '../../../../../components/address/DebtorAddressModal';
import CustomerDetails from '../../../../../components/sales/create/productBuilderKeywayVersion/CustomerDetails';
import queryString from 'query-string';
import {toast, ToastContainer} from 'react-toastify';
import {cloneDeep, isEmpty} from 'lodash';
import SalesOrderProductBuilderCustomerRetailDetail from '../../../../../components/modal/SalesOrderProductBuilderCustomerRetailDetail';
import retailService from "../../../../../services/retail/RetailService";
import SalesOrderProductBuilderOrderSummaryDetail from '../../../../../components/modal/SalesOrderProductBuilderOrderSummaryDetail';
import {
    BUNNINGS_STORE_ACCOUNT_IDs,
    PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_NOTES,
    taxRate
} from "../../../../../store/AppConstants";
import SalesOrderProductBuilderAdditionalPreApprovedRetailDiscount from '../../../../../components/modal/SalesOrderProductBuilderAdditionalPreApprovedRetailDiscount';
import SalesOrderProductBuilderOrderSummaryTableDefault from './SalesOrderProductBuilderOrderSummaryTableDefault';
import {productConstant} from "../../../../../store/ProductGroups";

const addressFields = ["contactName", "phone", "email", "delCompany", "delAddr1", "delAddr2", "delCity", "delState", "delPostCode"]

export default class SalesOrderProductBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer: {},
            defaultAccountID: "",
            order: {},
            products: [],
            isOpenSavedAddressModal: false,
            savedAddresses: [],
            loadingOrder: false,
            loadingProducts: false,
            loadingSavedAddresses: false,
            isLoadingSubmitOrder: false,
            isLoadingSubmitQuote: false,
            isLoadingOrderSummary: false,
            discountByProductCode: {},
            isLoadingDiscountByProductCode: {},
            freight: salesOrderProductBuilderV1Service.initFreightObj(),
            packagingAndHandling: {
                rules: [],
                chargeByProductCode: {}
            },
            user: {},
            navigation: {
                tab: {
                    list: [],
                    active: {}
                }, subTab: {
                    all: [],
                    list: [],
                    active: {}
                }
            },
            isOpenRetailCustomerDetail: false,
            productMarkupByProductCode: {},
            isOpenOrderSummaryDetail: false,
            isOpenRetailPreApprovedDiscountModal: false,
            formError: {
                isValid: true,
                errors: []
            },
            orderSubmitTried: false,
            orderSummaryDownloadTried: false,
        }
        ;
        this.userService = new UserService();
        this.orderService = new OrderService();
        this.customerService = new CustomerService();
        this.getOrder = this.getOrder.bind(this);
        this.submitOrder = this.submitOrder.bind(this);
        this.downloadOrderSummary = this.downloadOrderSummary.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.toggleProductTab = this.toggleProductTab.bind(this);
        this.handleOrderChange = this.handleOrderChange.bind(this);
        this.toggleSavedAddressModal = this.toggleSavedAddressModal.bind(this);
        this.autoSelectDefaultFirstCustomer = this.autoSelectDefaultFirstCustomer.bind(this);
        this.fetchCustomer = this.fetchCustomer.bind(this);
        this.fetchDebtorDiscount = this.fetchDebtorDiscount.bind(this);
        this.fetchPackagingAndHandlingRules = this.fetchPackagingAndHandlingRules.bind(this);
        this.fetchFreightRules = this.fetchFreightRules.bind(this);
        this.checkMetroPostcode = this.checkMetroPostcode.bind(this);
        this.toggleRetailCustomerModal = this.toggleRetailCustomerModal.bind(this);
        this.getProductMarkup = this.getProductMarkup.bind(this);
        this.toggleOrderSummaryModal = this.toggleOrderSummaryModal.bind(this);
        this.toggleRetailPreApprovedDiscountModal = this.toggleRetailPreApprovedDiscountModal.bind(this);
        this.handleFormError = this.handleFormError.bind(this);
    }

    componentDidMount() {
        let {order} = this.state;
        let defaultAccountID = "";
        order.id = 0;
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            if (!isNaN(searchParams.orderNum)) {
                order.id = parseInt(searchParams.orderNum);
            }
            if (searchParams.accountID) {
                defaultAccountID = searchParams.accountID;
            }
        }
        let user = this.userService.getLoggedInUser();
        if (!defaultAccountID && user.isExternalUser) {
            defaultAccountID = user.menu.accountID;
        }
        this.setState({user, defaultAccountID});
        this.getOrder(order.id);
    }

    toggleRetailPreApprovedDiscountModal(data) {
        this.setState({isOpenRetailPreApprovedDiscountModal: data});
    }

    getProductMarkup(accountID) {
        if (accountID) {
            retailService.getProductMarkups(accountID).then(response => {
                let productMarkup = response.data;
                if (productMarkup) {
                    let {productMarkupByProductCode, products} = this.state;
                    products.forEach(product => {
                        let productMarkupIndex = (productMarkup || []).findIndex(x => x.productID === product.id);
                        if (productMarkupIndex > -1) {
                            productMarkupByProductCode[product.code] = productMarkup[productMarkupIndex];
                        }
                    });
                    this.setState({productMarkupByProductCode});
                }
            }).catch(error => {
                toast.error(error.message, {position: toast.POSITION.BOTTOM_CENTER});
            });
        }
    }

    toggleRetailCustomerModal(isOpenRetailCustomerDetail) {
        this.setState({isOpenRetailCustomerDetail: isOpenRetailCustomerDetail});
    }

    toggleOrderSummaryModal(isOpenOrderSummaryDetail) {
        this.setState({isOpenOrderSummaryDetail: isOpenOrderSummaryDetail});
    }

    getOrder(id) {
        let {order, freight, user} = this.state;
        if (id) {
            this.setState({loadingOrder: true});
            salesOrderProductBuilderV1Service.getSalesOrder(id).then(response => {
                user = this.state.user;
                order = response.data;
                order.custOrdNum = user.isExternalUser ? order.custOrdNum?.replace(getChildAccountID(order.accountID), "") : order.custOrdNum;
                order.pricing = {
                    price: 0,
                    discVal: 0,
                    tax: 0,
                    total: 0,
                    freight: {
                        charge: 0,
                        ruleId: 0
                    }, packagingAndHandlingCharges: 0
                };
                freight.selectedMethodOption = order.freightMethod;
                this.setState({order, loadingOrder: false, freight}, () => {
                    this.getProducts(order.accountID);

                });
            }).catch(error => {
                console.log(error);
                this.setState({loadingOrder: false});
                toast.error(handleErrorMessage(error));
            })
        }
        else {
            order = {
                pricing: {
                    price: 0,
                    discVal: 0,
                    tax: 0,
                    total: 0,
                    freight: {
                        charge: 0,
                        ruleId: 0
                    }, packagingAndHandlingCharges: 0
                },
                isAuthorityToLeave: false,
                isAgreementSigned: false,
                retailMarkupDiscount: 0,
                retailFirstName: "",
                retailLastName: "",
                retailEmail: "",
                retailPhone: "",
                customerEmail: "",
            };
            this.setState({order}, () => {
                if (!this.state.defaultAccountID) {
                    this.autoSelectDefaultFirstCustomer();
                }
            });
        }
    }

    autoSelectDefaultFirstCustomer() {
        // Default, trying to get first accountID from db and then refreshing with the same
        let requestBody = {
            accountID: "",
            includeChildren: true,
            includeClosed: false,
            includeInactive: false,
            parentAccountIDs: BUNNINGS_STORE_ACCOUNT_IDs
        };

        this.customerService.searchFirstDebtorAccountCompany(requestBody).then(response => {
            if (response.data) {
                let {defaultAccountID} = this.state;
                for (const [key, value] of Object.entries(response.data)) {
                    defaultAccountID = response.data[key].accountId;
                }
                this.setState({defaultAccountID});
            }
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    fetchCustomer(accountID) {
        this.customerService.searchCustomer(accountID).then(response => {
            if (response.data) {
                this.handleOrderChange("address", response.data);
                this.getProducts(accountID);
            }
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    fetchDebtorDiscount(accountID) {
        this.setState({isLoadingDiscountByProduct: true});
        this.customerService.getDiscount(accountID).then(response => {
            let productGroupDiscount = response.data;
            if (productGroupDiscount) {
                let {discountByProductCode, order, products} = this.state;
                let customDiscount = 0, productGroupDiscountIndex = -1;

                products.forEach(product => {
                    productGroupDiscountIndex = (productGroupDiscount || []).findIndex(pgd => pgd.productGroupID === product.productGroupID);
                    if (productGroupDiscountIndex > -1) {
                        //extract customer discount
                        discountByProductCode[product.code] = productGroupDiscount[productGroupDiscountIndex];
                        customDiscount = 0;
                        //extract order discount
                        if (!isEmpty(order) && !isEmpty(order.productGroupDiscounts) && order.accountID === accountID) {
                            productGroupDiscountIndex = (order.productGroupDiscounts || []).findIndex(pgd => pgd.productGroupID === product.productGroupID);
                            if (productGroupDiscountIndex > -1) {
                                customDiscount = order.productGroupDiscounts[productGroupDiscountIndex].discount;
                                if (customDiscount > 0) {
                                    discountByProductCode[product.code].discount = customDiscount;
                                }
                            }
                        }
                    }
                });

                this.setState({discountByProductCode, isLoadingDiscountByProduct: false});
            }
        }).catch(error => {
            this.setState({isLoadingDiscountByProduct: false});
            console.log(error);
            toast.error(handleErrorMessage(error));
        });
    }

    getProducts(accountID) {
        this.setState({loadingProducts: true});
        productService.getProducts().then(response => {
            let products = response.data;
            let productIndex, item;
            let {order, navigation} = this.state;
            //sorting
            products = getSorted(products, 'sortIndex', true);
            //filter applicable only
            products = salesOrderProductBuilderV1Service.getApplicableProducts(this.state.user, products, accountID, order);
            //check for saved order

            navigation.tab.list = [];
            navigation.subTab.list = [];
            navigation.subTab.all = [];

            (products || []).forEach(p => {
                p.isLoadingBuilder = true;
                p.items = [];
                p.savedItems = [];
                p.init = false;
                p.isSelectable = true;

                if (!p.parentProductID) {
                    navigation.tab.list.push(p);
                } else {
                    navigation.subTab.all.push(p);
                }

                //fetch corresponding saved order item into product
                if (order && order.id && order.salesOrderItems) {
                    p.savedItems = order.salesOrderItems.filter(soi => soi.productID === p.id);
                }
            });

            //update tab badge with item count
            (products || []).forEach((p, productIndex) => {
                p = salesOrderProductBuilderV1Service.updateProductItemCount(products, productIndex)
            });

            this.setState({
                products,
                navigation,
                loadingProducts: false
            }, () => {
                //set active tab
                productIndex = products.findIndex(p => !isEmpty(p.savedItems));
                productIndex = productIndex > -1 ? productIndex : 0;
                if (products.length > 0) {
                    this.toggleProductTab(products[productIndex].code);
                }

                this.fetchDebtorDiscount(accountID);
                this.fetchFreightRules(accountID, false);
                this.fetchPackagingAndHandlingRules(accountID);
                /*if (this.state.user.isExternalUser) {
                    this.getProductMarkup(accountID);
                }*/
                (this.state.products || []).forEach(p => {
                    salesOrderProductBuilderV1Service.getProductBuilder(p.id, accountID).then(response => {
                        products = this.state.products;
                        productIndex = findIndex(products, 'id', response.data.productID);
                        products[productIndex].builder = response.data;
                        products[productIndex].isLoadingBuilder = false;
                        products[productIndex].pricing = {
                            price: 0,
                            discount: 0,
                        };
                        products[productIndex].builder.stockByProdCode = arrayToDictionary(products[productIndex].builder.stocks, 'prodCode');
                        this.setState({products})
                    }).catch(error => {
                        console.log(error);
                        toast.error(handleErrorMessage(error));
                    })
                })
            });
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error));
            this.setState({loadingProducts: false});
        });
    }

    fetchFreightRules(accountID, isFreightResetRequired) {
        let freight = this.state.freight;
        if (accountID) {
            freight.isLoadingAllRules = true;
            this.setState({freight});
            freightService.getCustomerFreightOptions(accountID).then(response => {
                let {freight, order} = this.state;
                freight.allRules = response.data;
                freight.isLoadingAllRules = false;
                freight = salesOrderProductBuilderV1Service.updateFreightMethodOptions(freight, order.delPostCode, isFreightResetRequired);
                this.setState({freight}, () => {
                    this.checkMetroPostcode(this.state.order.delPostCode);
                });
            }).catch(error => {
                freight.isLoadingAllRules = false;
                this.setState({freight});
                console.log(error);
                toast.error(handleErrorMessage(error));
            });
        } else {
            let freight = {
                allRules: [],
                methodOptions: [],
                selectedMethodOption: "",
                isMetroPostcode: false,
                isLoadingAllRules: false,
                isLoadingMetroPostcodeCheck: false
            };
            this.setState({freight});
        }
    }

    fetchPackagingAndHandlingRules(accountID) {
        let {packagingAndHandling, products} = this.state;
        let productIndex = -1;
        packagingHandlingChargesService.fetchSpecialCustomerOrDefaultRules(accountID).then(response => {
            packagingAndHandling.rules = response.data;
            products.forEach(product => {
                productIndex = (packagingAndHandling.rules || []).findIndex(r => r.productID === product.id);
                if (productIndex > -1) {
                    packagingAndHandling.chargeByProductCode[product.code] = packagingAndHandling.rules[productIndex].charge;
                }
            });
            this.setState({packagingAndHandling});
        }).catch(error => {
            console.error(error);
            toast.error(handleErrorMessage(error));
        });
    }

    checkMetroPostcode(postcode) {
        let {freight, order} = this.state;
        if (postcode && postcode.length > 0) {

            freight.isLoadingMetroPostcodeCheck = true;
            this.setState({freight});
            addressService.getAddressStandardByPostcode(postcode).then(response => {
                order = this.state.order;
                freight = this.state.freight;
                freight.isMetroPostcode = response.data && response.data.isMetro;
                freight.isLoadingMetroPostcodeCheck = false;
                order.pricing.freight = salesOrderProductBuilderV1Service.calculateFreightCharges(order, freight);
                order.pricing = salesOrderProductBuilderV1Service.calculateOrderTotal(order);
                this.setState({order, freight});
            }).catch(error => {
                freight = this.state.freight;
                freight.isLoadingMetroPostcodeCheck = false;
                this.setState({freight});
                toast.error(handleErrorMessage(error));
            })
        } else {
            order.pricing.freight = salesOrderProductBuilderV1Service.calculateFreightCharges(order, freight);
            order.pricing = salesOrderProductBuilderV1Service.calculateOrderTotal(order);
            this.setState({order, freight});
        }
    }

    handleOrderChange(key, change) {
        let {order, products, freight, productMarkupByProductCode, customer, orderSubmitTried, user} = this.state;
        let price, discVal, discountedPrice, tax, wwCost, isQuiklokHasItems, isNonQuiklokHasItems;
        let formError = {
            isValid: true,
            errors: []
        };

        switch (key) {
            case "customer":
                if (order.accountID !== change) {
                    order.accountID = change;
                    order.custOrdNum = "";
                    order.notes = "";
                    order.jobRef = "";
                    order.debtorInvoiceOrdNum = 0;
                    order.salesOrderItems = [];
                    order.salesOrderStatus = [];
                    order.maxWidth = 0;
                    order.pricing = {
                        price: 0,
                        discVal: 0,
                        tax: 0,
                        total: 0,
                        freight: {
                            charge: 0,
                            ruleId: 0
                        }
                    };
                    order.retailMarkupDiscount = 0;
                    this.setState({order}, () => {
                        if (order.accountID) {
                            this.fetchCustomer(order.accountID);
                        } else {
                            this.handleOrderChange("address", {});
                        }
                    });

                }
                break;
            case "address":
                customer = change;

                order.contactName = change.contactName ? change.contactName : "Store Manager";
                order.phone = change.phone;
                order.email = change.email;
                order.fax = change.fax;
                order.delCompany = change.company;
                order.delAddr1 = change.address1;
                order.delAddr2 = change.address2;
                order.delAddr3 = change.address3;
                order.delAddr4 = change.address4;
                order.delCity = change.city;
                order.delState = change.state;
                order.delPostCode = change.postCode;

                this.setState({order, isOpenSavedAddressModal: false, formError, customer}, () => {
                    this.fetchFreightRules(order.accountID, false);
                });
                break;
            case "custOrdNum":
                order[key] = change ? change.trim() : "";
                if(orderSubmitTried){
                    formError = salesOrderProductBuilderV1Service.validateForm(formError, order, "", user);
                }
                this.setState({order, formError});
                break;
            case "retailFirstName":
            case "retailLastName":
            case "retailEmail":
            case "notes":
            case "attachmentIDs":
            case "isAgreementSigned":
                order[key] = change;
                formError = salesOrderProductBuilderV1Service.validateForm(formError, order, "", user);
                this.setState({order, formError});
                break;
            case "product":
                order.pricing = {
                    price: 0,
                    discVal: 0,
                    tax: 0,
                    total: 0,
                    freight: {
                        charge: 0,
                        ruleId: 0
                    }, packagingAndHandlingCharges: 0,
                    retailMarkupValue: 0,
                    retailMarkupValueDiscVal: 0,
                    retailMarkupValueTax: 0,
                    retailMarkupValueTotal: 0,
                };
                order.maxWidth = 0;
                isQuiklokHasItems = salesOrderProductBuilderV1Service.isProductHasItems(products, productConstant.Quiklok.code, "include");
                isNonQuiklokHasItems = salesOrderProductBuilderV1Service.isProductHasItems(products, productConstant.Quiklok.code, "exclude");
                products.forEach((p, productIndex) => {
                    if (p.pricing && p.pricing.price) {
                        price = p.pricing.price ? p.pricing.price : 0;
                        discVal = p.pricing.discVal ? p.pricing.discVal : 0;

                        if (order.isRetail) {
                            p.pricing.retailMarkup = salesOrderProductBuilderV1Service.getRetailMarkupByProduct(p, productMarkupByProductCode);
                            discountedPrice = price - discVal;
                            tax = discountedPrice * taxRate;
                            wwCost = discountedPrice + tax;

                            p.pricing.retailMarkupValue = salesOrderProductBuilderV1Service.calculateValueWithPercentage(wwCost, p.pricing.retailMarkup);
                        } else {
                            p.pricing.retailMarkup = 0;
                            p.pricing.retailMarkupValue = 0;
                        }

                        order.pricing.price += price;
                        order.pricing.discVal += discVal;

                        order.pricing.retailMarkupValue += p.pricing.retailMarkupValue;

                        order.pricing.packagingAndHandlingCharges += p.pricing.packagingAndHandlingCharges ? p.pricing.packagingAndHandlingCharges : 0;
                    }
                    if (p.maxWidth) {
                        if (order.maxWidth < p.maxWidth) {
                            order.maxWidth = p.maxWidth;
                        }
                    }

                    p = salesOrderProductBuilderV1Service.updateProductItemCount(products, productIndex, "ITEMS");

                    switch (p.code){
                        case productConstant.Quiklok.code:
                            p.isSelectable = !isNonQuiklokHasItems;
                            break;
                        default:
                            p.isSelectable = !isQuiklokHasItems;
                            break;
                    }
                });

                order.pricing.freight = salesOrderProductBuilderV1Service.calculateFreightCharges(order, freight);
                order.pricing = salesOrderProductBuilderV1Service.calculateOrderTotal(order);
                this.setState({order, products});
                break;
            case "freightMethod":
                if (change) {
                    freight.selectedMethodOption = change;
                    order.pricing.freight = salesOrderProductBuilderV1Service.calculateFreightCharges(order, freight);
                    order.pricing = salesOrderProductBuilderV1Service.calculateOrderTotal(order);
                    this.setState({freight, order});
                }
                break;
            case "retailMarkupDiscount":
                order.retailMarkupDiscount = change;
                order.pricing = salesOrderProductBuilderV1Service.calculateOrderTotal(order);
                this.setState({order, isOpenRetailPreApprovedDiscountModal: false});
                break;
            case "retailPhone":
            case "cosNumber":
                if (isNaN(change)) {
                    return;
                }
                order[key] = change;
                formError = salesOrderProductBuilderV1Service.validateForm(formError, order, "", user);
                this.setState({ order, formError });
                break;
            default:
                if (key) {
                    order[key] = change;
                    this.setState({order});
                }
                else {
                    toast.error("key not found");
                }
        }
    }

    handleFormError(key){
        let {order,  formError, user} = this.state;
        formError = salesOrderProductBuilderV1Service.validateForm(formError, order, key, user);
        this.setState({formError});
    }

    toggleSavedAddressModal(change) {
        this.setState({isOpenSavedAddressModal: change});
    }

    toggleProductTab(change) {
        let {products, navigation} = this.state;
        let tabIndex = -1, subTabIndex = -1, productIndex;

        if (change === "ORDER_FORM_TAB") {
            navigation.tab.active = {
                id: "ORDER_FORM_TAB",
                parentProductID: null,
                code: "ORDER_FORM_TAB",
                name: "ORDER_FORM_TAB"
            }
        } else {
            productIndex = products.findIndex(p => p.code === change);
            if (productIndex > -1) {
                tabIndex = navigation.tab.list.findIndex(i => i.code === products[productIndex].code);
                if (tabIndex > -1) {
                    navigation.tab.active = navigation.tab.list[tabIndex];

                    navigation.subTab.list = products.filter(p => p.parentProductID === navigation.tab.active.id);
                    subTabIndex = 0;
                } else {
                    subTabIndex = navigation.subTab.list.findIndex(i => i.code === products[productIndex].code);
                    subTabIndex = subTabIndex > -1 ? subTabIndex : 0;
                }
                if (!isEmpty(navigation.subTab.list)) {
                    navigation.subTab.active = navigation.subTab.list[subTabIndex];
                }
            }
        }

        this.setState({navigation});
    }

    submitOrder(orderType) {
        let {order, products, freight, packagingAndHandling, discountByProductCode, productMarkupByProductCode, orderSubmitTried, isLoadingSubmitOrder, user, formError} = this.state;
        orderSubmitTried = true;
        order.isQuote = orderType === "Quote";
        let request = salesOrderProductBuilderV1Service.initOrderSubmitRequest(order, user, freight);
        formError = {
            isValid: true,
            errors: []
        };

        (products || []).forEach(product => {
            salesOrderProductBuilderV1Service.prepareSalesOrderItem(order, product, packagingAndHandling, discountByProductCode, request, formError.errors, productMarkupByProductCode);
            formError.isValid = isEmpty(formError.errors);
            if (!formError.isValid) {
                this.handleOrderChange("product", product);
            }
        });

        formError = salesOrderProductBuilderV1Service.validateForm(formError, request, "", user);
        if (!formError.isValid) {
            if (!isEmpty(formError.errors)) {
                this.setState({ formError });
                toast.dismiss();
                formError.errors.forEach(error => {
                    toast.info(error);
                });
                this.setState({formError, orderSubmitTried});
                return;
            }
        }
        let orderData = order;
        this.setState({
            formError,
            orderSubmitTried,
            isLoadingSubmitOrder: !request.isQuote,
            isLoadingSubmitQuote: request.isQuote,
            order
        });
        salesOrderProductBuilderV1Service.saveSalesOrderKeyway(request).then(response => {
            let order = response.data;
            this.setState({ order });
            toast.success("Order submitted!");
            this.props.history.push({
                pathname: "/sales/order/submitted",
                search: "?" + queryString.stringify({
                    ordNum: order.ordNum,
                    submitStrategy: order.submitStrategy,
                    isQuote: order.isQuote,
                    documentType: order.documentType,
                    poNumber: order.custOrdNum
                }),
                state: { orderData, products, freight, packagingAndHandling, discountByProductCode, productMarkupByProductCode }
            });
        }).catch(error => {
            console.error(error);
            this.setState({ isLoadingSubmitOrder: false, isLoadingSubmitQuote: false });
            toast.error(handleErrorMessage(error));
        });
    }

    downloadOrderSummary() {
        let {order, products, freight, packagingAndHandling, discountByProductCode, productMarkupByProductCode, isLoadingOrderSummary, orderSummaryDownloadTried, formError} = this.state;
        let message, request, customerParams, item;
        orderSummaryDownloadTried = true;
        request = {
            accountID: order.accountID,
            email: order.email,
            delCompany: order.delCompany,
            salesOrderItems: [],
            retailFirstName: order.retailFirstName,
            retailEmail: order.retailEmail,
            retailPhone: order.retailPhone
        };
        formError = {
            isValid: true,
            errors: []
        };

        (products || []).forEach(product => {
            salesOrderProductBuilderV1Service.prepareSalesOrderItem(order, product, packagingAndHandling, discountByProductCode, request, formError.errors, productMarkupByProductCode);

            formError.isValid = isEmpty(formError.errors);
            if (!formError.isValid) {
                this.handleOrderChange("product", product);
            }
        });


        formError = salesOrderProductBuilderV1Service.validateFormOrderSummaryPreCreate(formError, request, "");
        if (!formError.isValid) {
            if (!isEmpty(formError.errors)) {
                this.setState({formError});
                formError.errors.forEach(error => {
                    toast.info(error);
                });
                this.setState({formError, orderSummaryDownloadTried});
                return;
            }
        }


        customerParams = {
            "ordDate": getDateString(new Date(), "YYYY-MM-DD HH:mm:ss"),
            "accountID": request.accountID,
            "customerName": request.retailFirstName,
            "storeEmail": request.retailEmail,
            "company": order.delCompany,

        };
        let items =  [];
        request.salesOrderItems.forEach(soi => {
            salesOrderProductBuilderV1Service.prepareCustomerSalesSummaryReportJson(soi, item, customerParams, items);
        });
        isLoadingOrderSummary = true;
        this.setState({
            formError,
            isLoadingOrderSummary,
            order,
            orderSummaryDownloadTried
        });

        let fileDownloadWrapperRequest = {
            items
        };

        this.orderService.downloadFile("Customer Order Summary", 0, "", fileDownloadWrapperRequest).then(response => {
            if (response.data) {
                window.open(URL.createObjectURL(response.data));
            }
            this.setState({isLoadingOrderSummary: false});
        }).catch(error => {
            console.error(error);
            this.setState({isLoadingOrderSummary: false});
            toast.error(handleErrorMessage(error));
        })
    }

    render() {
        let { order, user, formError, isOpenRetailCustomerDetail, isOpenOrderSummaryDetail, isOpenRetailPreApprovedDiscountModal, customer, loadingOrder, products, loadingProducts, discountByProductCode, isOpenSavedAddressModal, isLoadingSubmitOrder, isLoadingSubmitQuote, isLoadingOrderSummary, freight, packagingAndHandling, navigation, productMarkupByProductCode, orderSubmitTried, orderSummaryDownloadTried } = this.state;
        let role = user.menu ? user.menu.role : "";
        return (<div>
            {user.isExternalUser
                ? <div>
                    <CustomerDashboardQuickLinks size={"sm"}/>
                    <hr/>
                </div>
                : null
            }
            <div>
                {
                    loadingOrder
                        ? <Spinner color={"primary"}/>
                        : <div>
                            {order.id
                                ? <Row>
                                    <Col><h5>#{order.debtorInvoiceOrdNum}</h5></Col>
                                    <Col>
                                        <div className="text-right">
                                            <h6>
                                                <small className="mr-2">Created on</small>
                                                {changeFormatOfDateString(order.createdDateString, "DD/MM/YYYY", "ddd, Do MMM YYYY")}
                                            </h6>
                                        </div>

                                    </Col>
                                </Row>
                                : null
                            }
                            <CustomerDetails
                                defaultAccountID={this.state.defaultAccountID}
                                order={order}
                                handleOrderChange={this.handleOrderChange}
                                toggleRetailCustomerModal={this.toggleRetailCustomerModal}
                                isExternalUser={user.isExternalUser}
                                orderSubmitTried={orderSubmitTried}
                                orderSummaryDownloadTried={orderSummaryDownloadTried}
                                formError={formError}
                                customer={customer}
                            />


                            <Card className={"mt-3"}>
                                <CardBody>
                                    <div><strong>Order Items</strong></div>
                                    {
                                        loadingProducts
                                            ? <Spinner color={"primary"} className={"mt-2"}/>
                                            : null
                                    }
                                </CardBody>
                                {
                                    loadingProducts
                                        ? null
                                        : <SalesOrderProductBuilderNavigationTab
                                            order={order}
                                            user={user}
                                            products={products}
                                            navigation={navigation}
                                            discountByProductCode={discountByProductCode}
                                            packagingAndHandling={packagingAndHandling}
                                            productMarkupByProductCode={productMarkupByProductCode}
                                            handleOrderChange={this.handleOrderChange}
                                            toggleProductTab={this.toggleProductTab}
                                        />
                                }
                            </Card>
                            {
                                salesOrderProductBuilderV1Service.isVisibleOrderSummary(order, products)
                                    ? <Row className={"mt-3"}>
                                        <Col xl={7} lg={7} md={6} sm={12} xs={12}>
                                            <Card>
                                                <CardBody>
                                                    <div>
                                                        <p><strong>Delivery</strong></p>
                                                        <Row>
                                                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                                                Delivery Address
                                                            </Col>
                                                            <Col xl={"00010,09009,09111,01005".includes(order.accountID) ? 7 : 10}
                                                                lg={"00010,09009,09111,01005".includes(order.accountID) ? 7 : 10}
                                                                md={"00010,09009,09111,01005".includes(order.accountID) ? 7 : 10} sm={12} xs={12}>
                                                                <div>
                                                                    {order.contactName
                                                                        ? <div title={"Contact Person"}>
                                                                            <i className="text-muted fa fa-user fa-fw mr-1"
                                                                               aria-hidden="true"/>
                                                                            {order.contactName}
                                                                        </div>
                                                                        : null
                                                                    }
                                                                    {
                                                                        order.phone
                                                                            ? <div title={"Phone"}>
                                                                                <a href={"tel:" + order.phone}>
                                                                                    <i className="text-muted fa fa-phone fa-fw mr-1"
                                                                                       aria-hidden="true"/>
                                                                                    {order.phone}
                                                                                </a>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                    {
                                                                        order.email
                                                                            ? <div title={"Email"}>
                                                                                <a href={"mailto:" + order.email}>
                                                                                    <i className="text-muted fa fa-envelope fa-fw mr-1"
                                                                                       aria-hidden="true"/>
                                                                                    {order.email}
                                                                                </a>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                    {order.delCompany ?
                                                                        <div>
                                                                            <i className="text-muted fa fa-building fa-fw mr-1"
                                                                               aria-hidden="true"/>{order.delCompany}
                                                                        </div>
                                                                        : null}
                                                                    <div>
                                                                        <a href={"http://maps.google.com/?q=" + getDeliveryAddressString(order)}
                                                                           target="_blank">
                                                                            <i className="text-muted fa fa-map-marker fa-fw mr-1"
                                                                               aria-hidden="true"/>
                                                                            {getDeliveryAddressString(order)}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                                                                <div className="text-right">
                                                                    {addressFields.map(f => {
                                                                        return formError[f]
                                                                            ? <FormText
                                                                                color="danger"
                                                                                className={"mb-1"}>
                                                                                <i className="fa fa-exclamation-triangle mr-2"
                                                                                   aria-hidden="true"/>
                                                                                {formError[f]}
                                                                            </FormText>
                                                                            : null
                                                                    })}
                                                                    {"00010,09009,09111,01005".includes(order.accountID) ?
                                                                        <Button color={"primary"}
                                                                            size={"sm"}
                                                                            onClick={() => this.toggleSavedAddressModal(true)}>
                                                                            <i className="fa fa-pencil mr-2"
                                                                                aria-hidden="true" /> Edit delivery address
                                                                        </Button>
                                                                        : null}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className="mt-3">
                                                        {
                                                            ((!user.isExternalUser) && salesOrderProductBuilderV1Service.isVisibleOrderPricingTable(order))
                                                                ? <SalesOrderProductBuilderOrderSummaryTableDefault
                                                                    order={order}
                                                                    products={products}
                                                                    discountByProductCode={discountByProductCode}
                                                                    freight={freight}
                                                                    handleOrderChange={this.handleOrderChange}
                                                                />
                                                                : null

                                                        }
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col xl={5} lg={5} md={6} sm={12} xs={12}>
                                            <Card>
                                                <CardBody>
                                                    <div>
                                                        <Button color={"primary"}
                                                            block={true}
                                                            disabled={isLoadingOrderSummary}
                                                            onClick={this.downloadOrderSummary}>
                                                            {
                                                                isLoadingOrderSummary
                                                                    ? <Spinner color="light" size={"sm"}
                                                                        className={"mr-2"} />
                                                                    : <i className="fa fa-print mr-2"
                                                                        aria-hidden="true" />
                                                            }
                                                            {
                                                                isLoadingOrderSummary
                                                                    ? "Printing Customer Order Summary"
                                                                    : "Print Customer Order Summary"
                                                            }
                                                        </Button>
                                                        <small className="text-info text-justify"><i className="fa fa-info-circle  mr-2" aria-hidden="true" />The Customer Order Summary contains barcodes to assist with scanning finelines into COS</small>
                                                        <hr />
                                                        <div>
                                                            <div>
                                                                <div>PO Number <span className="text-primary">(Mandatory if submitting as an order)</span></div>
                                                                <InputGroup>
                                                                    {
                                                                        user.isExternalUser
                                                                            ? <InputGroupAddon
                                                                                addonType="prepend">{getChildAccountID(order.accountID)}</InputGroupAddon>
                                                                            : ""
                                                                    }
                                                                    <Input
                                                                        onChange={(e) => this.handleOrderChange("custOrdNum", e.target.value)}
                                                                        onBlur={(e) => this.handleFormError("custOrdNum")}
                                                                        type="text"
                                                                        name="custOrdNum"
                                                                        value={order.custOrdNum || ""}
                                                                        invalid={(orderSubmitTried && formError.custOrdNum)}
                                                                        placeholder="Enter PO Number here" />
                                                                </InputGroup>
                                                                {orderSubmitTried && formError.custOrdNum
                                                                    ? <FormText
                                                                        color="danger">
                                                                        <i className="fa fa-exclamation-triangle mr-2"
                                                                            aria-hidden="true" />
                                                                        {formError.custOrdNum}
                                                                    </FormText>
                                                                    : null}
                                                            </div>
                                                            <div className={"mt-2"}>
                                                                <div>Notes</div>
                                                                <Input
                                                                    style={{ minHeight: 75 }}
                                                                    onChange={(e) => this.handleOrderChange("notes", e.target.value,)}
                                                                    type="textarea"
                                                                    name="notes"
                                                                    value={order.notes || ""}
                                                                    invalid={(orderSubmitTried && formError.notes)}
                                                                    placeholder="Enter notes here" />
                                                            </div>
                                                            {
                                                                (orderSubmitTried && formError.notes)
                                                                    ? <FormText
                                                                        color="danger">
                                                                        <i className="fa fa-exclamation-triangle mr-2"
                                                                            aria-hidden="true" />
                                                                        {"Max " + PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_NOTES + " characters allowed!"}
                                                                    </FormText>
                                                                    : null
                                                            }
                                                        </div>
                                                        <div className={"mt-2 text-left"}>
                                                            Please make sure your order is correct.
                                                        </div>
                                                        <div className={"mt-2"}>
                                                            <div className="form-check">
                                                                <Input type={"checkbox"}
                                                                    className={"form-check-input"}
                                                                    id={"agreement"}
                                                                    name={"isAgreementSigned"}
                                                                    checked={order.isAgreementSigned}
                                                                    invalid={(orderSubmitTried && formError.isAgreementSigned)}
                                                                    onChange={(e) => this.handleOrderChange(e.target.name, e.target.checked)}
                                                                />
                                                                <Label className="form-check-label text-justify"
                                                                    for={"agreement"}>

                                                                    By ticking this box I am confirming that the stated
                                                                    measurements
                                                                    are correct. The order is final and once the blinds are cut
                                                                    to
                                                                    size no changes can be made.
                                                                </Label>
                                                            </div>
                                                            {
                                                                (orderSubmitTried && formError.isAgreementSigned)
                                                                    ? <FormText
                                                                        color="danger">
                                                                        <i className="fa fa-exclamation-triangle mr-2"
                                                                            aria-hidden="true" />
                                                                        {formError.isAgreementSigned}
                                                                    </FormText>
                                                                    : null
                                                            }

                                                        </div>
                                                        {(salesOrderProductBuilderV1Service.isSubmitButtonShouldBeVisible(order, role))
                                                            ? <Button color={"primary"}
                                                                size={"lg"}
                                                                block={true}
                                                                className="mt-2"
                                                                disabled={isLoadingSubmitOrder}
                                                                onClick={() => this.submitOrder("Order")}>
                                                                {
                                                                    isLoadingSubmitOrder
                                                                        ? <Spinner color="light" size={"sm"}
                                                                            className={"mr-2"} />
                                                                        : <i className="fa fa-floppy-o mr-2"
                                                                            aria-hidden="true" />
                                                                }
                                                                {
                                                                    isLoadingSubmitOrder
                                                                        ? "Submitting Order"
                                                                        : "Submit Order"
                                                                }
                                                            </Button>
                                                            : null}
                                                        <div className="mt-2">COS Number</div>
                                                        <InputGroup>
                                                            <Input
                                                                onChange={(e) => this.handleOrderChange("cosNumber", e.target.value)}
                                                                onBlur={(e) => this.handleFormError("cosNumber")}
                                                                type="text"
                                                                name="cosNumber"
                                                                value={order.cosNumber || ""}
                                                                invalid={formError.cosNumber}
                                                                placeholder="Enter COS Number here" />
                                                        </InputGroup>
                                                        {formError.cosNumber
                                                            ? <FormText
                                                                color="danger">
                                                                <i className="fa fa-exclamation-triangle mr-2"
                                                                    aria-hidden="true" />
                                                                {formError.cosNumber}
                                                            </FormText>
                                                            : null}
                                                        {salesOrderProductBuilderV1Service.isSubmitQuoteButtonShouldBeVisible(order, role)
                                                            ? <Button color={"info"}
                                                                size={"lg"}
                                                                block={true}
                                                                className="mt-2"
                                                                disabled={isLoadingSubmitQuote || isLoadingSubmitOrder}
                                                                onClick={() => this.submitOrder("Quote")}>
                                                                {
                                                                    isLoadingSubmitQuote
                                                                        ? <Spinner color="light" size={"sm"}
                                                                            className={"mr-2"} />
                                                                        : <i className="fa fa-floppy-o mr-2"
                                                                            aria-hidden="true" />
                                                                }
                                                                {isLoadingSubmitQuote
                                                                    ? "Saving Quote"
                                                                    : "Save Quote"
                                                                }
                                                            </Button>
                                                            : null}
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    : null
                            }
                            {
                                isOpenSavedAddressModal
                                    ? <DebtorAddressModal
                                        selected={{
                                            company: order.delCompany,
                                            contactName: order.contactName,
                                            phone: order.phone,
                                            email: order.email,
                                            fax: order.fax,
                                            address1: order.delAddr1,
                                            address2: order.delAddr2,
                                            address3: order.delAddr3,
                                            address4: order.delAddr4,
                                            city: order.delCity,
                                            state: order.delState,
                                            postCode: order.delPostCode,
                                            country: 'AUSTRALIA',
                                        }}
                                        isOpen={isOpenSavedAddressModal}
                                        toggleModel={this.toggleSavedAddressModal}
                                        handleChange={(selectedAddress) => this.handleOrderChange("address", selectedAddress)}
                                        accountID={order.accountID}
                                    />
                                    : null
                            }


                        </div>
                }
            </div>
            {isOpenRetailCustomerDetail ?
                <SalesOrderProductBuilderCustomerRetailDetail
                    isOpen={isOpenRetailCustomerDetail}
                    toggle={this.toggleRetailCustomerModal}
                    order={order}
                    handleChange={this.handleOrderChange}
                />
                : null}
            {isOpenOrderSummaryDetail ?
                <SalesOrderProductBuilderOrderSummaryDetail
                    order={order}
                    products={products}
                    discountByProductCode={discountByProductCode}
                    isOpen={isOpenOrderSummaryDetail}
                    toggle={this.toggleOrderSummaryModal}/>
                : null
            }
            {isOpenRetailPreApprovedDiscountModal ?
                <SalesOrderProductBuilderAdditionalPreApprovedRetailDiscount
                    order={order}
                    isOpen={isOpenRetailPreApprovedDiscountModal}
                    toggle={this.toggleRetailPreApprovedDiscountModal}
                    handleOrderChange={this.handleOrderChange}
                />
                : null
            }
            <ToastContainer/>
        </div>)
    }
}