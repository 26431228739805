import axios from './axios';
import axiosFileDownloader from './axiosFileDownloader';
import queryString from 'query-string';

export default class OrderService {

    getNotes(ordNum) {
        return axios.post('api/order/notes/list?ordNum=' + ordNum);
    }

    AddDebtorsInvoice_Notes(Notes, OrdNum, UserName) {
        return axios.post('api/order/notes/add?Notes=' + Notes + '&OrdNum=' + OrdNum + '&UserName=' + UserName);
    }

    getOrder(ordNum, includeOrderItems, isShowOnInvoiceOrderItems, includeOrderItemProductLabels, navigation) {
        let request = {
            ordNum, includeOrderItems, isShowOnInvoiceOrderItems, includeOrderItemProductLabels, navigation
        };
        return axios.post('api/order/one?' + queryString.stringify(request), {}, {
            timeout: 120 * 1000
        });
    }

    getEDIOrder(salesOrderID, navigation) {
        let request = {
            salesOrderID, navigation
        };
        return axios.post('api/order/edi/one?' + queryString.stringify(request), {}, {
            timeout: 120 * 1000
        });
    }


    forceMatchSalesOrderEDIMatchManager(EDISalesOrderId, OrdNum, ActionCdName) {
        let request = {
            EDISalesOrderId, OrdNum, ActionCdName
        };
        return axios.post('api/order/edi/match/manager/force-match', request, {
            timeout: 120 * 1000
        });
    }

    getOrderPartList(ordNum, itemNum) {
        let request = {ordNum};
        if (itemNum) {
            request.itemNum = itemNum;
        }
        return axios.post('api/order/one/part-list?' + queryString.stringify(request));
    }

    getOrderCostByOrderNo(OrderNo, Discount) {
        return axios.post('api/order/items/list/cost?orderNo=' + OrderNo + '&discount=' + Discount)
    }

    GenerateDebtorOrderReport(OrderNo) {
        return axiosFileDownloader.post('api/order/OrderDetailReport?OrderNo=' + OrderNo);
    }

    GenerateDebtorOrderSummaryReport(OrderNo) {
        return axiosFileDownloader.post('api/order/OrderSummaryReport?OrderNo=' + OrderNo);
    }

    updateRequiredDate(orderNum, requiredDate) {
        return axios.put('api/sales/orders/update/requiredDate?orderNum=' + orderNum + '&requiredDate=' + requiredDate);
    }

    updateProductionReadyDate(orderNum, productionReadyDate) {
        return axios.put('api/sales/orders/update/productionReadyDate?orderNum=' + orderNum + '&productionReadyDate=' + productionReadyDate);
    }

    updateNote(orderNum, note) {
        return axios.put('api/sales/orders/update/note?orderNum=' + orderNum + '&note=' + note);
    }

    SendStatementInEmail(requestBody) {
        return axios.post('api/customer/statement/export/pdf/email', requestBody);
    }

    getAllGatewayOrders(requestBody, type) {
        return axios.post('/api/order/gateway?type=' + type, requestBody)
    }

    getAllGatewayOrdersSally(requestBody, type) {
        return axios.post('/api/order/gateway/sally/order/list?type=' + type, requestBody)
    }

    getAllGatewayOrdersEDI(requestBody, type) {
        return axios.post('/api/order/gateway/edi/order/list?type=' + type, requestBody)
    }

    getAllKeywayOrders(requestBody, type) {
        return axios.post('/api/order/gateway/keyway/salesorders?type=' + type, requestBody)
    }


    getOrderDebtorCategoryDetails(OrderNumbersRequest) {
        return axios.post('/api/order/debtor/category/details', OrderNumbersRequest)
    }


    updateGatewayOrders(ordNums, type) {
        return axios.post('/api/order/status/update?status=' + type + '&ordNums=' + ordNums)
    }

    getOrderStatuses() {
        return axios.get('api/order/status/get');
    }

    getSubmittedOrdersSummary(request) {
        return axios.post('/api/order/gateway/orders/summary', request);
    }

    getSubmittedOrdersDebtorSummary(request) {
        return axios.post('/api/order/gateway/orders/debtor/summary', request);
    }

    downloadInvoice(OrderNo) {
        return axiosFileDownloader.post('api/customer/invoice/download?OrderNo=' + OrderNo);
    }

    downloadFile(type, ordNum, productGroupCodes, fileDownloadWrapperRequest) {
        switch (type) {
            case "Production Label":
            case "Customer Order Summary":
            default:
                return axiosFileDownloader.post('api/order/file/download?' + queryString.stringify({
                    type,
                    ordNum,
                    productGroupCodes
                }), fileDownloadWrapperRequest);
        }
    }

    getCountOfOrder(type, branch) {
        return axios.get('/api/order/gateway/count?type=' + type + '&branch=' + branch)
    }

    getCountOfOrderByStatus(req) {
        return axios.post('/api/order/gateway/count/by-status', req);
    }

    getOrderSummaryForOpeningSpecificTab(ordNum,request) {
        return axios.post('/api/order/gateway/tab/details?ordNum=' + ordNum,request);
    }

    getOrderRelatedDetails(ordNum) {
        return axios.get('/api/order/gateway/details?ordNum=' + ordNum);
    }

    exportAllGatewayOrders(requestBody, type) {
        return axiosFileDownloader.post('/api/order/gateway/export?type=' + type, requestBody)
    }

    changeOrderStatusForMultipleOrders(req) {
        return axios.post('/api/order/orderNumbers/change/status', req);
    }

    getCustomerOrderDetails(req) {
        return axios.post('/api/order/customer/details', req);
    }

    downloadDailySummaryReport(fromDateString, toDateString) {
        return axiosFileDownloader.post('api/order/download/salesSummary?fromDateString=' + fromDateString + '&toDateString=' + toDateString);
    }

    SendInvoiceInEmail(requestBody) {
        return axios.post('api/customer/invoice/sendEmail', requestBody);
    }


    updateSalesOrderDiscount(request) {
        return axios.put('api/order/discount/update', request);
    }

    getDebtorInvoiceItems(req) {
        return axios.post('api/order/invoice/items/list', req);
    }

    getOrderStatus(OrderNo) {
        return axios.get('api/order/status?OrderNo=' + OrderNo);
    }

    checkIsPowderCoatOrder(OrderNo) {
        return axios.get('api/order/checkIfPowderCoatOrder?OrderNo=' + OrderNo);
    }

    getStockInfoByProdcodes(req) {
        return axios.post('api/production/enquiry/order/item/stock/info', req);
    }

    shareDebtorInvoiceOnEmail(ordNum, request) {
        return axios.post("api/order/share/debtor/email?ordNum=" + ordNum, request);
    }

    updateOrderOverrideHold(ordNum, value) {
        return axios.post("/api/order/update/order/overrideHold?ordNum=" + ordNum + "&overrideHoldValue=" + value);
    }

    getConsignmentTrackUrl(courier, consignNum){
        let url = "";
        if(courier && consignNum){
            switch (courier){
                case "TOLL":
                case "Global Ex":
                    url =  "https://www.myteamge.com?externalSearchQuery="+consignNum;
                    break;
                case "Border Express":
                    url = "https://bexonline.borderexpress.com.au/bxo/track?consignmentNumber="+consignNum;
                    break;
            }
        }
        return url;
    }

    updateOrderStatus(ordNum, option) {
        return axios.post('api/order/update/status?ordNum=' + ordNum + '&option=' + option);
    }
}