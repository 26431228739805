import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Nav,
    NavItem,
    NavLink,
    Spinner,
    TabContent,
    TabPane
} from 'reactstrap';
import classnames from 'classnames';
import "react-datepicker/dist/react-datepicker.css";
import {ToastContainer} from 'react-toastify';
import UserService from '../../services/UserService';
import SearchGatewayOrder from "../../components/search/SearchGatewayOrder";
import GatewayOrdersCustomer from "./GatewayOrdersCustomer";
import moment from 'moment';
const date = new Date();
const tabs = [
    {
        key: "quote",
        label: "Quotes",
        statusOptions: [
            { label: "All", value: "" },
            { label: "Active", value: "0" },
            { label: "Cancelled", value: "-1" },
            { label: "Completed", value: "5" }
        ]
    },
    {
        key: "submitted",
        label: "Outstanding Orders",
        statusOptions: [
            {label: "All", value: ""},
            {label: "Active", value: "0"},
            {label: "Ready To Pick", value: "1"},
            {label: "Being Picked", value: "2"},
            {label: "Being Despatched", value: "3"},
        ]
    },
    {
        key: "completed",
        label: "Shipped Orders",
        statusOptions: [
            {label: "All", value: ""},
            {label: "Invoiced", value: "4"},
        ]
    },
    {
        key: "cancelled",
        label: "Cancelled Orders",
        statusOptions: [
            {label: "All", value: ""},
            {label: "Cancelled", value: "-1"},
        ]
    }
];

export default class OrderGatewayCustomerPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: props.activeTab,
            user: {},
            accountID: "",
            isLoading:false,
            filter: {
                consignNum: "",
                ordNum: null,
                custOrdNum: "",
                accountID: "",
                startDate: moment(date).subtract(90, 'day').toDate(),
                endDate: date,
                jobRef: "",
                delCompany: "",
                orderRef: "",
                isBunning: true
            },
        };
        this.userService = new UserService();
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        let user = this.userService.getLoggedInUser();
        let accountID = user.menu.accountID;
        this.setState({user, accountID});
    }

    toggle(tab) {
        let {activeTab} = this.state;
        if (activeTab !== tab) {
            activeTab = tab;
            this.setState({activeTab});
        }
    }

    handleChange(change, key) {
        let { filter } = this.state;
        switch (key) {
            case "retailPhone":
            case "cosNumber":
                if (isNaN(change)) {
                    return;
                }
            default:
                filter[key] = change;
                this.setState({ filter });
        }
    }

    render() {
        let { activeTab, filter, isLoading , user} = this.state;
        return (
            <div>
                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {tabs.map((tab, index) =>
                                <NavItem className={"hoverableItem"} key={index}>
                                    <NavLink
                                        className={classnames({active: activeTab === tab.key})}
                                        onClick={() => {
                                            this.toggle(tab.key);
                                        }}>
                                        <Button style={{textDecoration: "none"}}
                                                size={"sm"}
                                            color={"link"}>{tab.label}&nbsp;</Button>
                                        {activeTab === tab.key && isLoading
                                            ? <Spinner size={"sm"} color={"primary"}/>
                                            : null}

                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        <SearchGatewayOrder
                            handleChange={this.handleChange} type={"customerOrder"}
                            filterRequest={filter} currentUser={user} tab={activeTab} />
                        <br />
                        <TabContent activeTab={activeTab}>
                            {tabs.map((tab, index) => (
                                <TabPane tabId={tab.key} key={index}>
                                    {tab.key === activeTab ?
                                        <div>
                                            <GatewayOrdersCustomer activeTab={activeTab} filter={filter}/>
                                        </div>
                                        : null}
                                </TabPane>
                            ))}
                        </TabContent>
                    </CardBody>
                </Card>
                <ToastContainer/>
            </div>
        );
    }
}