import React, { Component } from 'react';
import { ListGroup, ListGroupItem } from "reactstrap";
import { Button } from 'reactstrap';
import { Link } from "react-router-dom";
import queryString from 'query-string';

export default function OrderEnquiryCustomerDetail(props) {
    let { order, salesOrder, currentUser, viewMode } = props;
    return <div>
        <ListGroup className={"list-group-horizontal-sm"}>
            <ListGroupItem>
                <div style={{ minWidth: 150 }}>
                    <p className={"pb-1 mb-1"}><strong>Store</strong></p>
                    <div>
                        <Link
                            className="btn btn-primary btn-sm"
                            style={{ color: "white" }}
                            to={"/customer/account/detail?" + queryString.stringify({ accountID: order.accountID })}
                            title={"Click here to see account details"}
                        >{order.accountID}</Link>
                    </div>
                    <div><h6>{order.delCompany}</h6></div>
                </div>
            </ListGroupItem>
            <ListGroupItem>
                <div style={{ minWidth: 150 }}>
                    <p className={"pb-1 mb-1"}>
                        <strong>Shipping Address</strong>
                    </p>
                    <a
                        href={"http://maps.google.com/?q=" + order.shippingAdrress}
                        target="_blank">
                        <div>
                            {order.delAddr1}
                        </div>
                        <div>
                            {order.delAddr2}
                        </div>
                        <div>
                            {order.delCity}
                        </div>
                        <div>
                            {order.delState}{' '}{order.delPostCode}
                        </div>
                    </a>
                </div>
            </ListGroupItem>
            {viewMode !== "production" ?
                <>
                    <ListGroupItem>
                        <div style={{ minWidth: 150 }}>
                            <p className={"pb-1 mb-1"}>
                                <strong>Contact</strong>
                            </p>
                            {order.contactName
                                ? <p className={"mb-0"}><i
                                    className="text-muted fa fa-user fa-fw mr-1"
                                    aria-hidden="true" />{order.contactName}</p>
                                : null
                            }
                            <div title={"Phone"}>
                                {
                                    order.phone
                                        ?
                                        <a href={"tel:" + order.phone}> <i
                                            className="text-muted fa fa-phone fa-fw mr-1"
                                            aria-hidden="true" />{order.phone}</a>
                                        : null
                                }
                            </div>
                            <div title={"Fax"}>
                                {
                                    order.fax
                                        ?
                                        <a href={"fax:" + order.fax}> <i
                                            className="text-muted fa fa-fax fa-fw mr-1"
                                            aria-hidden="true" />{order.fax}</a>
                                        : null
                                }
                            </div>
                        </div>
                    </ListGroupItem>
                    <ListGroupItem>
                        <div style={{ minWidth: 150 }}>
                            <p className={"pb-1 mb-1"}><strong>Store's Parent
                                Company</strong></p>
                            <h6>{order.p_Company}</h6>

                            <div>
                                <a
                                    href={"http://maps.google.com/?q=" + order.parentCompanyAdrress}
                                    target="_blank">
                                    <div>{order.p_Addr1}</div>
                                    <div>{order.p_Addr2}</div>
                                    <div>{order.p_Addr3}</div>
                                    <div>{order.p_Addr4}</div>
                                    <div>{order.p_City}</div>
                                    <div>{order.p_State}{' '}{order.p_PostCode}</div>
                                </a>
                            </div>
                        </div>
                    </ListGroupItem>
                    {
                        ((order.salesOrder && (order.salesOrder.retailFirstName || order.salesOrder.retailEmail || order.salesOrder.retailPhone)))
                            ? <ListGroupItem>
                                <div style={{ minWidth: 150, maxWidth: 250 }}>
                                    {
                                        order.salesOrder.retailFirstName ?
                                            <div>
                                                <p className={"pb-1 mb-1"}>
                                                    <strong>Customer</strong>
                                                </p>
                                                <p className={"mb-0"}>
                                                    <i className="text-muted fa fa-user fa-fw mr-1"
                                                        aria-hidden="true" />
                                                    {order.salesOrder.retailFirstName}
                                                </p>
                                            </div> : null
                                    }
                                    {
                                        order.salesOrder.retailPhone ?
                                            <a href={"tel:" + order.salesOrder.retailPhone}> <i
                                                className="text-muted fa fa-phone fa-fw mr-1"
                                                aria-hidden="true" />{order.salesOrder.retailPhone}</a>
                                            : null
                                    }
                                    {
                                        order.salesOrder.retailEmail ?
                                            <div>
                                                <a className="d-flex" href={"mailto:" + order.salesOrder.retailEmail}>
                                                    <i className="text-muted fa fa-envelope-o fa-fw mr-1 mt-1 ml-1"
                                                        aria-hidden="true" />
                                                    <span className="text-break">{order.salesOrder.retailEmail}</span>
                                                </a>
                                            </div> : null
                                    }
                                </div>
                            </ListGroupItem>
                            : null
                    }
                    {
                        (salesOrder && salesOrder.isRetail)
                            ? <ListGroupItem>
                                <div style={{ minWidth: 150 }}>
                                    <p className={"pb-1 mb-1"}><strong>Retail Customer Detail</strong>
                                        {(order.salesOrderJobStatusId < 95 && currentUser.isExternalUser) ?
                                            <Button
                                                className={"pl-0 float-right"}
                                                color={"link"}
                                                size={"sm"}
                                                title={"click here to edit"}
                                                onClick={() => props.editCustomer(true)}>
                                                <i className="fa fa-pencil ml-2"
                                                    aria-hidden="true" />
                                            </Button>
                                            : null}
                                    </p>
                                    <h6>{salesOrder.retailFirstName} {salesOrder.retailLastName}</h6>
                                    <div title={"phone"}>
                                        {
                                            salesOrder.retailPhone
                                                ?
                                                <a href={"tel:" + salesOrder.retailPhone}> <i
                                                    className="text-muted fa fa-phone fa-fw mr-1"
                                                    aria-hidden="true" />{salesOrder.retailPhone}</a>
                                                : null
                                        }
                                    </div>
                                    <div title={"email"}>
                                        {(salesOrder.retailEmail) ?
                                            <span>
                                                <a href={"mailto:" + salesOrder.retailEmail}>
                                                    <i className="text-muted fa fa-envelope-o mr-1"
                                                        aria-hidden="true" />
                                                    {salesOrder.retailEmail}</a>
                                            </span>
                                            : null
                                        }
                                    </div>
                                    <div title={"retailAddress"}>
                                        {(salesOrder.retailAddress) ?
                                            <a href={"http://maps.google.com/?q=" + salesOrder.retailAddress}
                                                target="_blank">
                                                <i className="text-muted fa fa-map-marker mr-1"
                                                    aria-hidden="true" />
                                                <span>{salesOrder.retailAddressLine1}{' '}</span>
                                                <span>{salesOrder.retailAddressLine2}{' '}</span>
                                                <span>{salesOrder.retailAddressLine3}{' '}</span>
                                                <span>{salesOrder.retailAddressLine4}{' '}</span>
                                                <span>{salesOrder.retailCity}{' '}</span>
                                                <span>{salesOrder.retailState}{' '}{salesOrder.retailPostCode}</span>
                                            </a> : null}
                                    </div>
                                </div>
                            </ListGroupItem>
                            : null
                    }
                </>
                : null
            }
        </ListGroup>
    </div>
}