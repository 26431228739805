import React, {Component} from "react";
import {Button, Spinner } from 'reactstrap';
import DebtorInvoiceNotesModal from "../modal/DebtorInvoiceNotesModal";
import OrderService from "../../services/OrderService";
import {handleErrorMessage, getSorted, changeFormatOfDateString} from "../../services/CommonService";
import {toast} from 'react-toastify';
import {isEmpty} from 'lodash';

export default class SalesOrderNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingNotes: false,
            openDebtorInvoiceNotesModal: false,
            ordNum: this.props.ordNum,
            notes: [],
        };

        this.toggleOpenDebtorInvoiceNotesModal = this.toggleOpenDebtorInvoiceNotesModal.bind(this);
        this.refresh = this.refresh.bind(this);
        this.orderService = new OrderService();
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        if (this.state.ordNum) {
            this.setState({isLoadingNotes: true});
            this.orderService.getNotes(this.state.ordNum).then(response => {
                let notes = response.data;
                notes = getSorted(notes, 'debtorInvoiceNoteID', false);
                this.setState({notes, isLoadingNotes: false});
            }).catch(error => {
                this.setState({isLoadingNotes: false});
                console.error(error.data);
                toast.error(handleErrorMessage(error));

            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.ordNum !== nextProps.ordNum) {
            this.setState({ordNum: nextProps.ordNum,}, function () {
                this.refresh();
            });
        }
    }

    toggleOpenDebtorInvoiceNotesModal() {
        this.setState((prevState) => ({openDebtorInvoiceNotesModal: !prevState.openDebtorInvoiceNotesModal}));
    }

    render() {
        let {ordNum, openDebtorInvoiceNotesModal, notes, isLoadingNotes} = this.state;
        let {isReadOnly} = this.props;
        return (
            <div>
                <p className={"pb-1 mb-1"}><strong>Internal Notes</strong>
                    {
                        isReadOnly
                            ? null
                            : <Button color="link" size={"sm"}
                                      onClick={() => this.toggleOpenDebtorInvoiceNotesModal()}
                                      className="float-right ml-2">
                                <i className="fa fa-plus mr-2" aria-hidden="true"/>
                                Add
                            </Button>
                    }
                </p>
                <div style={{
                    maxHeight: '200px',
                    overflowY: 'auto'
                }}>
                    {isLoadingNotes
                        ? <Spinner color={"primary"} size={"sm"}/>
                        :
                        <div>
                            {isEmpty(notes)
                                ? null
                                : <ul style={{padding: "unset", marginLeft: 1}}>
                                    {(notes || []).map((item, index) =>
                                        <li key={index}>
                                            <div className={"lead-remark-list-item"}>
                                                <div className={"lead-remark-list-item-text"}>
                                                    <small className="text-muted">
                                                        <i className="fa fa-user text-muted mr-2" aria-hidden="true"/>
                                                        {item.name}
                                                    </small>
                                                    <p className="mb-1">{item.notes}</p>
                                                    <div className="text-right">
                                                        <small className="text-muted">
                                                            <i className="fa fa-clock-o mr-2" aria-hidden="true"/>
                                                            {changeFormatOfDateString(item.dateAdded, "DD/MM/YYYY HH:mm:ss", "DD MMM YYYY hh:mm a")}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            }
                        </div>
                    }
                </div>
                <DebtorInvoiceNotesModal ordNum={ordNum}
                                         notes={notes}
                                         isOpen={openDebtorInvoiceNotesModal}
                                         toggleModel={this.toggleOpenDebtorInvoiceNotesModal}
                                         refresh={this.refresh}
                />
            </div>
        )
    }
}