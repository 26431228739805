import React, {Component} from 'react';
import { Badge, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Table, Button, Spinner, ButtonGroup } from 'reactstrap';
import SalesCustomerOrderService from '../../services/SalesCustomerOrderService';
import OrderService from '../../services/OrderService';
import {Link} from "react-router-dom";
import {
    changeFormatOfDateString,
    getDateString,
    handleErrorMessage,
    getShipmentStatusColorName, getProductGroupString, equalsTo, isDeepEqual
} from '../../services/CommonService';
import NumberFormat from "react-number-format";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import cloneDeep from 'lodash/cloneDeep';
import {toast} from 'react-toastify';
import classnames from "classnames";
import SalesOrderItemProductDetailModal from '../../components/modal/SalesOrderItemProductDetailModal';
import ShowShipmentStatusLogDetailModal from "../../components/modal/ShowShipmentStatusLogDetailModal";
import queryString from 'query-string';
import RivaModal from '../../components/modal/RivaModal';
import ConfirmModal from "../../components/modal/ConfirmModal";

export default class GatewayOrdersCustomer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "ordDate",
                        direction: false
                    },
                    filterRequest: {},
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            salesOrderJobStatuses: {
                statuses: [],
                statusesById: {}
            },
            loading: false,
            activeTab: props.activeTab,
            isOpenShipmentStatusLogModal: false,
            isOpenProductGroupModal: false,
            isOpenRivaModal: false,
            selectedOrder: null,
            selectedOrderIndex: null,
            showConfirmCancel: false,
        };

        this.getOrders = this.getOrders.bind(this);
        this.getStore = this.getStore.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.orderService = new OrderService();
        this.salesCustomerOrderService = new SalesCustomerOrderService();
        this.openProductGroupModal = this.openProductGroupModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.openShipmentStatusLogModal = this.openShipmentStatusLogModal.bind(this);
        this.toggleShipmentStatusLogModal = this.toggleShipmentStatusLogModal.bind(this);
        this.toggleCancelModal = this.toggleCancelModal.bind(this);
        this.handleOrderCancellation = this.handleOrderCancellation.bind(this);
    }

    componentDidMount() {
        let { orders} = this.state;

        this.getSalesOrderJobStatuses();
        orders.request.filterRequest = cloneDeep(this.props.filter);
        this.setState({orders}, () => {
            this.getOrders(orders);
        })

    }

    getSalesOrderJobStatuses() {
        this.orderService.getOrderStatuses().then(response => {
            let {salesOrderJobStatuses} = this.state;
            salesOrderJobStatuses.statuses = response.data;
            salesOrderJobStatuses.statuses.forEach(status => {
                salesOrderJobStatuses.statusesById[status.id] = status;
            });
            this.setState({salesOrderJobStatuses});
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    componentWillReceiveProps(nextProps) {
        let {activeTab, orders} = this.state;

        if (
            activeTab !== nextProps.activeTab
            || (!isDeepEqual(this.props.filter, orders.request.filterRequest))
        ) {
            orders.request.filterRequest = cloneDeep(this.props.filter);
            this.setState({activeTab: nextProps.activeTab, orders}, () => {
                this.getOrders(orders);
            });
        }
    }

    getOrders(orders) {
        let {activeTab} = this.state;
        this.setState({loading: true});
        let request = cloneDeep(orders.request);

        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }
        this.salesCustomerOrderService.getAllCustomerOrders(request, activeTab.toUpperCase()).then(response => {
            orders.response = response.data;
            this.setState({orders, loading: false});
        }).catch(error => {
            orders.response = [];
            this.setState({loading: false, orders});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }

    toggleCancelModal(change, index, order) {
        this.setState({ showConfirmCancel: change, selectedOrderIndex: index, selectedOrder: order });
    }

    handleOrderCancellation() {
        let { selectedOrder, selectedOrderIndex } = this.state;
        this.takeActionOnOrder(selectedOrder.ordNum, selectedOrderIndex, "Rejected");
        this.toggleCancelModal(false, null, {});
    }

    takeActionOnOrder(ordNum, orderIndex, option) {
        let { orders } = this.state;
        orders.response.records[orderIndex].isLoadingStatusUpdate = option;
        this.setState({ orders });
        this.orderService.updateOrderStatus(ordNum, option).then(response => {
            orders.response.records[orderIndex].isLoadingStatusUpdate = "";
            if (option === "Rejected") {
                toast.success("Quote Cancelled");
            } else {
                toast.success("Order Status Updated");
            }
            this.setState({ orders }, () => this.getOrders(orders));
        }).catch(error => {
            orders.response.records[orderIndex].isLoadingStatusUpdate = "";
            this.setState({ orders });
            console.log(error);
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        });
    }

    handleChange(change, key) {
        let {orders} = this.state;
        switch (key) {
            case "sortKey":
                if (change) {
                    if (orders.request.sortRequest.key === change) {
                        orders.request.sortRequest.direction = !orders.request.sortRequest.direction;
                    } else {
                        orders.request.sortRequest.key = change;
                        orders.request.sortRequest.direction = false;
                    }
                    this.getOrders(orders);
                }
                break;
            case "pageSize":
                orders.request.pageRequest[key] = change;
                this.getOrders(orders);
                break;
            case "currentPage":
                orders.request.pageRequest[key] = change;
                this.getOrders(orders);
                break;
        }

    }

    openShipmentStatusLogModal(order) {
        this.setState({ selectedOrder: order, isOpenShipmentStatusLogModal: true });
    }

    toggleShipmentStatusLogModal(change) {
        this.setState({ isOpenShipmentStatusLogModal: change });
    }

    getStore(orderRequest) {
        let {pageRequest, filterRequest} = orderRequest;
        let { activeTab, salesOrderJobStatuses, hasOrderGatewayReadonlyPrivilege } = this.state;
        let store = [
            {
                key: "srno",
                label: "Sr. No.",
                type: "default",
                colSpan: 1,
                minWidth: 30,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-center",
                showColumn: true,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (recordIndex + 1)}</span>
                }

            },
            {
                key: "accountID",
                label: "Account",
                type: "default",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-center",
                showColumn: true,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return value;
                }
            },
            {
                key: "ordDate",
                label: activeTab === "quote" ? "Quote Date" : "Order Date",
                type: "date",
                colSpan: 1,
                minWidth: "150px",
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-center",
                showColumn: true,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return changeFormatOfDateString(value, "YYYY-MM-DD hh:mm", 'DD MMM YYYY HH:mm');
                }
            },
            {
                key: "retailFirstName",
                label: "Customer Name",
                type: "default",
                colSpan: 1,
                minWidth: "150px",
                sorterApplicable: true,
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle text-center",
                showColumn: activeTab === "quote",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return value;
                }
            },
            {
                key: "retailPhone",
                label: "Mobile Number",
                type: "default",
                colSpan: 1,
                minWidth: "150px",
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-center",
                showColumn: activeTab === "quote",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return value;
                }
            },
            {
                key: "custOrdNum",
                label: "PO Number",
                type: "reference",
                colSpan: 1,
                minWidth: "120px",
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-center",
                showColumn: activeTab !== "quote",
                searchNode: <div>
                    <Input type={"text"}
                           placeholder={"Search..."}
                           name={"custOrdNum"}
                           value={filterRequest.custOrdNum}
                           onChange={(e) => this.handleChange(e.target.value, "custOrdNum")}/>
                </div>,
                render: function (value, record, recordIndex, data, ref) {
                    return <>
                        {
                            record.isRIVA ?
                                <ButtonGroup>
                                    <Button
                                        color="primary"
                                        size="sm"
                                        title={"Click here to view order in detail"}
                                        onClick={() => ref.setState({ isOpenRivaModal: true })}>
                                        {value}
                                    </Button>
                                    <Button
                                        color="primary"
                                        size="sm"
                                        title="RIVA"
                                        disabled
                                        style={{ opacity: 1 }}>
                                        RIVA
                                    </Button>
                                </ButtonGroup>
                                :
                                <Link
                                    className={"btn btn-sm btn-primary"}
                                    style={{ color: "white" }}
                                    title={"Click here to view order in detail"}
                                    to={"/sales/order/enquiry?" + queryString.stringify({ ordNum: record.ordNum })}>
                                    {value || "Quote"}
                                </Link>
                        }
                    </>
                },
            },
            {
                key: "cosNumber",
                label: "COS Number",
                type: "default",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-center",
                showColumn: activeTab === "quote",
                searchNode: <div>
                    <Input type="text"
                        placeholder="Search..."
                        name="cosNumber"
                        value={filterRequest.cosNumber}
                        onChange={(e) => this.handleChange(e.target.value, "cosNumber")} />
                </div>,
                render: function (value, record, recordIndex, data, ref) {
                    return value ? value : null;
                },
            },
            {
                key: "orderDetails",
                label: "Product",
                type: "product",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-center",
                showColumn: true,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    if (value) {
                        return <div><Button className="btn btn-sm" color="link"
                                            onClick={() => ref.openProductGroupModal(record)}>{getProductGroupString(record)}</Button>
                        </div>;
                    }

                },
            },
            {
                key: "salesOrderJobStatusID",
                label: "Status",
                type: "statusByID",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle text-center",
                searchNode: null,
                showColumn: activeTab !== "quote",
                render: function (value, record, recordIndex, data, ref) {
                    let color = "";
                    if (value) {
                        switch (value) {
                            case 90:
                            case 95:
                                color = "success";
                                break;
                            case 1:
                                color = "danger";
                                break;
                            default:
                                color = "primary";
                                break;
                        }
                        return <div className={"text-center"}>
                            <Badge size={'sm'}
                                   color={color}>{salesOrderJobStatuses.statusesById[value] ? salesOrderJobStatuses.statusesById[value].statusDescription : ""}</Badge>
                        </div>;
                    }
                }
            },
            {
                key: "cancelReason",
                label: "Cancellation Reason",
                type: "default",
                colSpan: 1,
                minWidth: "50px",
                sorterApplicable: false,
                valueClassName: "align-middle text-left",
                labelClassName: "align-middle text-center",
                showColumn: activeTab === "cancelled-ord/quot",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "ordTotal",
                label: activeTab === "quote" ? "Quote Total" : "Order Total",
                type: "currency",
                colSpan: 1,
                minWidth: "80px",
                sorterApplicable: true,
                valueClassName: "align-middle text-right",
                labelClassName: "hoverableItem align-middle text-center",
                showColumn: true,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <NumberFormat value={Math.floor(value)}
                                         displayType={'text'}
                                         decimalScale={2}
                                         thousandSeparator={true}
                                         fixedDecimalScale={true}
                                         prefix={'$'}/>;
                }
            },
            {
                key: "shipDate",
                label: "Shipment Date",
                type: "date",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-center",
                showColumn: activeTab === "completed",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY');
                }
            },
            {
                key: "couriers",
                label: "Ship By",
                type: "text",
                colSpan: 1,
                minWidth: 130,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-center",
                showColumn: activeTab === "completed",
                searchNode: <div>
                    <Input type={"text"}
                           placeholder={"Search..."}
                           name={"couriers"}
                           value={filterRequest.couriers}
                           onChange={(e) => this.handleChange(e.target.value, "couriers")}/>
                </div>,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "productionReadyDate",
                label: "ECD Date",
                type: "ECD",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-center",
                showColumn: activeTab === "submitted",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY');
                }
            },
            {
                key: "consignNum",
                label: "Connote No",
                type: "default",
                colSpan: 1,
                minWidth: "125px",
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-center",
                showColumn: activeTab === "completed",
                searchNode: <div>
                    <Input type={"text"}
                           placeholder={"Search..."}
                           name={"consignNum"}
                           value={filterRequest.consignNum}
                           onChange={(e) => this.handleChange(e.target.value, "consignNum")}/>
                </div>,
                render: function (value, record, recordIndex, data, ref) {
                    let url = record.consignmentTrackingUrl;
                    if (url) {
                        return (<a
                            href={url}
                            target={"_blank"}>
                            <Button color={"link"} size={"sm"}>
                                <i className="fa fa-truck mr-2" aria-hidden="true" /><small>{value}</small>
                            </Button>
                        </a>)
                    }
                    return <span><small>{value}</small></span>;
                }
            },
            {
                key: "shipmentETADate",
                label: "Shipment ETA",
                type: "default",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-center",
                showColumn: activeTab === "completed" || activeTab === "submitted",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY')}</span>
                }
            },
            {
                key: "shipmentStatusDesc",
                label: "Shipment Status",
                type: "default",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-center",
                showColumn: activeTab === "completed",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <Link size={"sm"} className={"ml-2"}
                                 onClick={() => ref.openShipmentStatusLogModal(record)}
                                 title={"view to see tracking status log"}>
                        <Badge color={getShipmentStatusColorName(record.shipmentStatusID)}>{value}</Badge>
                    </Link>;
                }
            },
            {
                key: "action",
                label: "Action",
                type: "default",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle text-center",
                showColumn: activeTab === "quote",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <>
                        <Link
                            className={classnames("mr-2", "btn", "btn-sm", "btn-primary", {
                                "disabled": hasOrderGatewayReadonlyPrivilege
                            })}
                            to={"/sales/order/create/product-builder/v1?orderNum=" + record.ordNum}>
                            <i className="fa fa-external-link mr-2" aria-hidden="true" />Open
                        </Link>
                        <Button color="danger"
                            outline={true}
                            disabled={record.isLoadingStatusUpdate === "Rejected" || hasOrderGatewayReadonlyPrivilege}
                            size={"sm"} onClick={() => ref.toggleCancelModal(true, recordIndex, record)}>
                            {record.isLoadingStatusUpdate && record.isLoadingStatusUpdate === "Rejected" ?
                                <Spinner size={"sm"} color="primary" className={"mr-2"} />
                                : <i className="fa fa-ban mr-2" aria-hidden="true" />
                            }
                            {record.isLoadingStatusUpdate === "Rejected" ? "Cancelling" : "Cancel"}
                        </Button>
                    </>;
                }
            },
        ];

        return store;
    }


    openProductGroupModal(order) {
        this.setState({selectedOrder: order, isOpenProductGroupModal: true});
    }

    toggleModal(change) {
        this.setState({isOpenProductGroupModal: change});
    }

    render() {
        let { orders, loading, isOpenProductGroupModal, selectedOrder, isOpenShipmentStatusLogModal, isOpenRivaModal, showConfirmCancel } = this.state;
        let {pageRequest, sortRequest} = orders.request;
        let store = this.getStore(orders.request);
        return (
            <div>
                {loading ? <Spinner size={"sm"} color={"primary"}/> : null}
                <Table hover bordered size={"sm"} responsive>
                    <thead>
                    <tr>
                        {(store || []).filter((item) => item.showColumn).map((item, index) => {
                            return (
                                <th key={index}
                                    onClick={() => this.handleChange(item.sorterApplicable ? item.key : "", "sortKey")}
                                    colSpan={item.colSpan}
                                    className={item.labelClassName}
                                    style={{minWidth: item.minWidth}}>
                                    {item.label}
                                    {
                                        item.sorterApplicable ?
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                    "fa-sort": (sortRequest.key !== item.key),
                                                    "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                    "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                }
                                            )} aria-hidden="true"/> : null
                                    }

                                </th>
                            );
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {(orders.response.records || []).map((order, i) => {
                        return (
                            <tr key={i}>
                                {(store || []).filter(item => item.showColumn).map((storeItem, index) => {
                                    return (
                                        <td key={index} className={storeItem.valueClassName}>
                                            {storeItem.render(order[storeItem.key], order, i, orders.response.records, this)}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    </tbody>

                </Table>
                <Row>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <div className={"text-left"} style={{maxWidth: 440}}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Showing
                                        {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                        {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                        {' '}of {orders.response.totalRecords}
                                        {' '}entries</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type={"select"}
                                    name={"pageSize"}
                                    value={pageRequest.pageSize}
                                    disabled={loading}
                                    onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                    <option value={10}>10 Rows</option>
                                    <option value={25}>25 Rows</option>
                                    <option value={50}>50 Rows</option>
                                    <option value={100}>100 Rows</option>
                                    <option value={500}>500 Rows</option>
                                </Input>
                            </InputGroup>


                        </div>
                    </Col>

                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <div className={"float-right"}>
                            <Pagination
                                activePage={pageRequest.currentPage}
                                itemsCountPerPage={pageRequest.pageSize}
                                totalItemsCount={orders.response.totalRecords}
                                pageRangeDisplayed={3}
                                onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                itemClass='page-item'
                                linkClass='page-link'
                                activeClass='active'
                                innerClass='pagination'
                                activeLinkClass='active'
                            />
                        </div>

                    </Col>
                </Row>

                {isOpenProductGroupModal ?
                    <SalesOrderItemProductDetailModal
                        order={selectedOrder}
                        isOpen={isOpenProductGroupModal}
                        toggle={this.toggleModal}/>
                    : null
                }
                {
                    isOpenShipmentStatusLogModal ?
                        <ShowShipmentStatusLogDetailModal
                            isOpen={isOpenShipmentStatusLogModal}
                            toggle={this.toggleShipmentStatusLogModal}
                            shipment={selectedOrder} />
                        : null
                }
                {
                    isOpenRivaModal
                        ? <RivaModal
                            isOpen={isOpenRivaModal}
                            toggle={() => this.setState({ isOpenRivaModal: false })}
                            handleCancel={() => this.setState({ isOpenRivaModal: false })}
                            handleSubmit={null}
                            primaryMessage="Details are not currently available in SALLY for RIVA orders. Please use Bunnings COS system to enquire on order details."
                            secondaryMessage=""
                            submitColor="success"
                            cancelColor="secondary"
                            icon="fa fa-warning fa-2x"
                            isCancelRequired={true}
                            isSubmitRequired={false}
                            loading={null}
                        />
                        : null
                }
                <ConfirmModal
                    isOpen={showConfirmCancel}
                    toggle={this.toggleCancelModal}
                    handleSubmit={() => this.handleOrderCancellation()}
                    handleCancel={() => this.toggleCancelModal(false, null, {})}
                    primaryMessage={"Do you want to cancel this quote?"}
                    submitColor={"danger"}
                    cancelColor={"secondary"}
                    icon={"fa fa-trash-o fa-2x"}
                    type="Cancelling..."
                    size="sm"
                />
            </div>
        );
    }
}