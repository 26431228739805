import React, {Component} from 'react';
import {Button, Input, Spinner, Table} from 'reactstrap';
import {Link as Link} from "react-router-dom";
import queryString from 'query-string';
import classnames from "classnames";
import {toast} from "react-toastify";
import ManageProductModal from '../../components/modal/ManageProductModal';
import {handleErrorMessage} from '../../services/CommonService';
import productService from '../../services/product/ProductService';
import ProductConfigurationStockUsageSearchModal from '../../components/modal/ProductConfigurationStockUsageSearchModal';

export default class ProductPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            productData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        name: '',
                        shortName: '',
                        displayName: '',
                        code: '' 
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                }
            },
            loading: false,
            cacheLoading: false,
            isOpenModal: false,
            selectedItem: {},
            productGroups: [],
            searchText: '',
            isOpenProductConfigurationStockSearchModal: false
        };
        this.refreshAgain = this.refreshAgain.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.cleanCache = this.cleanCache.bind(this);
        this.openProductConfigurationStockSearchModal = this.openProductConfigurationStockSearchModal.bind(this);
    }

    componentDidMount() {
        this.refreshAgain();
    }

    getProducts(productData) {
        this.setState({loading: true});
        productService.getAllProducts(productData.request).then(response => {
            productData.response = response.data;
            this.setState({productData, loading: false});
        }).catch(error => {
            this.setState({loading: false});
            toast.error(handleErrorMessage(error));
        });
    }

    allProductHeading({filterRequest}) {
        let productHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
            },
            {
                key: "name",
                label: "Name",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div><Input type="text" id="name" name="name"
                                        value={filterRequest.name}
                                        onChange={(e) => this.handleChange(e.target.value, "name")}
                                        placeholder="Search..."/></div>
            },
            {
                key: "shortName",
                label: "Short Name",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div><Input type="text" id="shortName" name="shortName"
                                        value={filterRequest.shortName}
                                        onChange={(e) => this.handleChange(e.target.value, "shortName")}
                                        placeholder="Search..."/></div>
            },
            {
                key: "displayName",
                label: "Display Name",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div><Input type="text" id="displayName" name="displayName"
                                        value={filterRequest.displayName}
                                        onChange={(e) => this.handleChange(e.target.value, "displayName")}
                                        placeholder="Search..."/></div>
            },
            {
                key: "code",
                label: "Code",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNode: <div><Input type="text" id="code" name="code"
                                        value={filterRequest.code}
                                        onChange={(e) => this.handleChange(e.target.value, "code")}
                                        placeholder="Search..."/></div>
            },
            {
                key: "parentProduct",
                label: "Parent Product",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
            },
            {
                key: "productGroupName",
                label: "Product Group",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle"
            },
            {
                key: "productionLine",
                label: "Production Line",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle"
            },
            {
                key: "sortIndex",
                label: "Sort Index",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle"
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "text-center",
                searchNode: <div className="text-center"><Link className="btn btn-primary btn-sm"
                                                               onClick={() => this.openModal()}>
                    <i className="fa fa-plus"/>&nbsp;
                    Add </Link></div>

            },
            {
                key: "configure",
                label: "Configure",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
            },
        ];
        return productHeading;
    }

    handleChange(change, key) {
        let {productData} = this.state;
        switch (key) {
            case "searchText":
                this.setState({searchText: change});
                break;
            case "sortKey":
                if (productData.request.sortRequest.key === change) {
                    productData.request.sortRequest.direction = !productData.request.sortRequest.direction;
                } else {
                    productData.request.sortRequest.key = change;
                    productData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getProducts(productData);
                }
                break;
            case "pageSize":
                productData.request.pageRequest[key] = change;
                productData.request.pageRequest.currentPage = 1;
                this.getProducts(productData);
                break;
            case "currentPage":
                productData.request.pageRequest[key] = change;
                this.getProducts(productData);
                break;

            default:
                productData.request.filterRequest[key] = change;
                productData.request.pageRequest.currentPage = 1;
                this.getProducts(productData);
                this.setState({productData});
        }

    }

    openModal(item) {
        this.setState({selectedItem: item, isOpenModal: true});
    }

    openProductConfigurationStockSearchModal(data) {
        this.setState({isOpenProductConfigurationStockSearchModal: data});
    }

    toggleModal(data) {
        this.setState({isOpenModal: data});
    }

    refreshAgain() {
        let {productData} = this.state;
        this.setState({isOpenModal: false});
        this.getProducts(productData);
    }

    cleanCache() {
        this.setState({cacheLoading: true});
        productService.cleanCache().then(response => {
            this.setState({cacheLoading: false});
            toast.success("Product Builder Cache Cleaned");
        }).catch(error => {
            this.setState({cacheLoading: false});
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        })
    }

    render() {
        let {productData, productGroups, searchText, cacheLoading, isOpenModal, selectedItem, isOpenProductConfigurationStockSearchModal} = this.state;
        let {filterRequest, sortRequest, pageRequest} = productData.request;
        let productHeading = this.allProductHeading(productData.request);
        return (
            <div>
                <div className={"text-right"}>
                    <Button color={"primary mb-2 mr-2"}
                            size={"sm"}
                            onClick={this.cleanCache} disabled={cacheLoading}>
                        {cacheLoading
                            ? <Spinner size="sm"
                                       className={"mr-2"}
                                       style={{color: "white"}}/>
                            : <i className="fa fa-eraser mr-2" aria-hidden="true"></i>}
                        Clean PB Cache
                    </Button>
                    <Button color={"primary mb-2"}
                            size={"sm"}
                            onClick={() => this.openProductConfigurationStockSearchModal(true)}>
                        <i className="fa fa-search mr-2" aria-hidden="true"/>
                        Search by Stock`s prodCode
                    </Button>

                </div>
                <Table responsive={true} striped bordered size={"sm"}>
                    <thead>
                    <tr>
                        {(productHeading || []).map((item, index) => {
                            return (
                                <th key={index}
                                    onClick={() => this.handleChange(item.key, "sortKey")}
                                    colSpan={item.colSpan}
                                    className={item.labelClassName}
                                    style={{minWidth: item.minWidth}}>
                                    {item.label}
                                    {
                                        item.sorterApplicable ?
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                    "fa-sort": (sortRequest.key !== item.key),
                                                    "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                    "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                }
                                            )} aria-hidden="true"/> : null
                                    }

                                </th>
                            );
                        })}
                    </tr>
                    <tr>
                        {(productHeading || []).map((item, index) => {
                            return (
                                <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                    {item.searchNode}
                                </td>
                            );
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {(productData.response.records || []).map((item, index) =>

                        <tr key={index}>
                            <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                            <td className={"align-middle"}>{item.name}</td>
                            <td className={"align-middle"}>{item.shortName}</td>
                            <td className={"align-middle"}>{item.displayName}</td>
                            <td className={"align-middle"}>{item.code}</td>
                            <td className={"align-middle"}>{item.parentProductID ? item.Name : null}</td>
                            <td className={"align-middle"}>{item.productGroupName}</td>
                            <td className={"align-middle"}>{item.productionLineName}</td>
                            <td className={"align-middle text-right"}>{item.sortIndex}</td>
                            <td className={"align-middle text-center"}><Button size={"sm"} color={"primary"}
                                                                   onClick={() => this.openModal(item)}>
                                <i className="fa fa-pencil mr-2"
                                   aria-hidden="true"/>
                                Edit
                            </Button></td>
                            <td className={"align-middle text-center"}>
                                <Link className={"btn btn-primary btn-sm mr-2"}
                                      to={"/inventory/product/configuration?" + queryString.stringify({productID: item.id})}>
                                    <i className="fa fa-cogs mr-2"
                                       aria-hidden="true"/>
                                    PB
                                </Link>
                            </td>
                        </tr>
                    )}

                    </tbody>
                </Table>

                {isOpenModal ?
                    <ManageProductModal
                        isOpen={isOpenModal}
                        toggle={this.toggleModal}
                        products={productData.response.records}
                        productGroups={productData.response.records}
                        selectedItem={selectedItem ? selectedItem : {}}
                        refreshAgain={this.refreshAgain}
                    /> : null}
                {
                    isOpenProductConfigurationStockSearchModal ?
                        <ProductConfigurationStockUsageSearchModal
                            isOpen={isOpenProductConfigurationStockSearchModal}
                            toggle={this.openProductConfigurationStockSearchModal}/>
                        : null}
            </div>
        );
    }
}