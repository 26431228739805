import React, {Component} from 'react';
import {
    Row,
    Col,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card,
    Button,
    CardHeader,
    CardBody,
    Badge
} from 'reactstrap';
import classnames from 'classnames';
import GatewayOrders from './GatewayOrders';
import {toast} from 'react-toastify';
import moment from 'moment';
import {getDateString, handleErrorMessage} from '../../services/CommonService';
import OrderService from '../../services/OrderService';
import cloneDeep from 'lodash/cloneDeep';
import UserService from '../../services/UserService';
import GatewayOrdersEDIUnmatched from "./GatewayOrdersEDIUnmatched";
import queryString from 'query-string';
import OrderGatewaySearchOrderNumModal from '../../components/modal/OrderGatewaySearchOrderNumModal';

const date = new Date();
const tabs = [
    {
        index: "0",
        key: "QUOTE",
        label: "Quotes"
    },
    {
        index: "1",
        key: "SUB",
        label: "Submitted Orders"
    },
    {
        index: "2",
        key: "EDIUM",
        label: "EDI Unmatched"
    },
    {
        index: "3",
        key: "PROD",
        label: "Orders in Production"
    },
    {
        index: "4",
        key: "DISP",
        label: "Dispatched Orders"
    },
    {
        index: "5",
        key: "CAN",
        label: "Cancelled Orders"
    },
];


export default class OrderGatewayPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: tabs[0].key,
            branches: [],
            reps: [],
            request: {
                pageRequest: {
                    currentPage: 1,
                    pageSize: 50
                },
                sortRequest: {
                    key: "ordDate",
                    direction: false
                },
                filterRequest: {
                    consignNum: "",
                    ordNum: null,
                    custOrdNum: "",
                    retailPhone: "",
                    accountID: "",
                    startDate: moment(date).subtract(90, 'day').toDate(),
                    endDate: date,
                    JobRef: "",
                    delCompany: "",
                    OrderRef: "",
                    isBunning: true
                }
            },
            orderCount: {
                quote: 0,
                sub: 0,
                can: 0,
                prod: 0,
                disp: 0,
                edium: 0,
            },
            selectedOrderNum: 0,
            ordNum: '',
            isOpenOrderItemsModal:false
        };

        this.userService = new UserService();
        this.orderService = new OrderService();
        this.toggle = this.toggle.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleOrderCount = this.handleOrderCount.bind(this);
        this.getCountOfOrderByStatus = this.getCountOfOrderByStatus.bind(this);
        this.toggleOrderItemsModal = this.toggleOrderItemsModal.bind(this);
        this.handleOrderClick = this.handleOrderClick.bind(this);
    }

    componentDidMount() {
        let {activeTab, request} = this.state;
        let { filterRequest } = request;
        let accountID = this.userService.getLoggedInUserAccountID();
        if (accountID) {
            filterRequest.accountID = accountID;
        }

        let searchParams = queryString.parse(this.props.location.search);
        if (searchParams.orderType) {
            activeTab =searchParams.orderType;
        }
        this.setState({request, activeTab});
        this.getCountOfOrderByStatus();
    }

    getCountOfOrderByStatus() {
        let {request} = this.state;

        let filterRequest = cloneDeep(request.filterRequest);
        if (filterRequest.startDate) {
            filterRequest.startDate = getDateString(filterRequest.startDate, "DD/MM/YYYY");
        }
        if (filterRequest.endDate) {
            filterRequest.endDate = getDateString(filterRequest.endDate, "DD/MM/YYYY");
        }

        this.orderService.getCountOfOrderByStatus(filterRequest).then(res => {
            this.setState({orderCount: res.data});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    toggle = tab => {
        if (this.state.activeTab !== tab) {
            let {request} = this.state;
            let {filterRequest, pageRequest} = request;
            filterRequest.salesOrderJobStatusId = null;
            filterRequest.debtorCategoryId = 0;
            filterRequest.productName = "";
            filterRequest.productId = null;
            filterRequest.isProductSummarySearched = false;
            pageRequest.currentPage = 1;
            this.setState({activeTab: tab, request});

            if (this.props.location) {
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.set("orderType", tab);
                searchParams.delete("currentPage");
                searchParams.delete('salesOrderJobStatusId');
                searchParams.delete('orderNum');
                searchParams.delete('selectedOrderNum');
                searchParams.delete("isProductSummarySearched");
                searchParams.delete("productName");
                searchParams.delete("debtorCategoryId");
                searchParams.delete("debtorCategoryLabel");

                let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
                window.history.pushState({path: newurl}, '', newurl);
            }
        }
    };

    handleFilter(data) {
        let {request} = this.state;
        request.filterRequest = data;
        this.setState({request});
    }

    handleOrderCount(type, count) {
        let {orderCount} = this.state;
        orderCount[type.toLowerCase()] = count;
        this.setState({orderCount});
    }

    renderSwitch(activeTab, selectedOrderNum, request) {
        let result = null;
        if (activeTab === "EDIUM") {
            result = <GatewayOrdersEDIUnmatched type={activeTab}
                                                handleFilterFromParent={this.handleFilter}
                                                request={request}
                                                handleOrderCountFromParent={this.handleOrderCount}
                                                selectedOrderNumber={selectedOrderNum}
            />;
        } else {
            result = <GatewayOrders type={activeTab}
                                    handleFilterFromParent={this.handleFilter}
                                    request={request}
                                    handleOrderCountFromParent={this.handleOrderCount}
                                    selectedOrderNumber={selectedOrderNum}
            />;
        }
        return result;
    }

    toggleOrderItemsModal(isOpenOrderItemsModal) {
        this.setState({ isOpenOrderItemsModal });
    }

    clearTabSelection() {
        this.setState({ activeTab: "" });
    }

    handleOrderClick(data) {
        let { activeTab, request } = this.state;
        let { filterRequest, pageRequest, sortRequest } = request;
        activeTab = data.orderType;
        filterRequest.salesOrderJobStatusId = data.salesOrderJobStatusId;
        pageRequest.currentPage = data.selectedPage;
        pageRequest.pageSize = data.pageSize;
        sortRequest.key = sortRequest.key;
        sortRequest.direction = sortRequest.direction;
        this.setState({ activeTab, request, selectedOrderNum: parseInt(data.ordNum), ordNum: data.ordNum });

        if (this.props.location) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("orderType", activeTab);
            searchParams.set("currentPage", data.selectedPage);
            searchParams.set('salesOrderJobStatusId', data.salesOrderJobStatusId);
            searchParams.set('selectedOrderNum', data.ordNum);
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({ path: newurl }, '', newurl);
        }

        this.toggleOrderItemsModal(false);
    }

    render() {
        let { activeTab, branches, request, orderCount, selectedOrderNum, isOpenOrderItemsModal } = this.state;
        return (
            <div>
                <Row className={"mb-2"}>
                    <Col xl={8} lg={6} md={6} sm={12} xs={12}>
                        <h5>Order Gateway</h5>
                    </Col>
                    <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                        <div className={"text-right"}>
                            <Button color={"primary"}
                                size={"sm"}
                                onClick={() => this.toggleOrderItemsModal(true)}>
                                <i className="fa fa-search mr-1 ml-1" aria-hidden="true" />
                                Search by Order No
                            </Button>

                        </div>
                    </Col>
                </Row>
                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {tabs.map((tab, index) =>
                                <NavItem className={"hoverableItem"} key={index}>
                                    <NavLink
                                        className={classnames({active: activeTab === tab.key})}
                                        onClick={() => {
                                            this.toggle(tab.key);
                                        }}>
                                        <Button style={{textDecoration: "none"}}
                                                size={"sm"}
                                                color={"link"}>{tab.label}&nbsp;
                                            <Badge color={"primary"}>{orderCount[tab.key.toLowerCase()]}</Badge>
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId={activeTab}>
                                {this.renderSwitch(activeTab, selectedOrderNum, request)}
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
                {
                    isOpenOrderItemsModal
                        ? <OrderGatewaySearchOrderNumModal
                            isOpen={isOpenOrderItemsModal}
                            toggle={this.toggleOrderItemsModal}
                            handleOrdernumClick={this.handleOrderClick}
                            request={this.state.request}
                        />
                        : null
                }
            </div>
        );
    }
}