import React, { Component } from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Input,
    Row,
    Spinner,
    Table,
} from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import { Link as Link } from "react-router-dom";
import { findIndex, getDistinct, handleErrorMessage } from '../../services/CommonService';
import productService from '../../services/product/ProductService';
import ProductConfigurationService from '../../services/product/ProductConfigurationService';
import classnames from 'classnames';
import queryString from 'query-string';
import DeleteModal from '../../components/modal/DeleteModal';
import { toast, ToastContainer } from 'react-toastify';
import UserService from '../../services/UserService';
import { v4 as uuidv4 } from 'uuid';

const inputTypes = ['text', 'select', 'checkbox'];


export default class ProductConfigurationPage extends Component {

    constructor(props) {
        super(props);


        this.state = {
            newItem: {},
            product: {
                id: 0
            },
            configurations: [],
            loading: {
                save: false,
                cofigurations: false,
                product: false
            },
            canDeleteProductionConfiguration: false
        };
        this.userService = new UserService();

        this.getProductConfigurations = this.getProductConfigurations.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
        this.saveProductConfiguration = this.saveProductConfiguration.bind(this);
        this.handleDeleteProductConfiguration = this.handleDeleteProductConfiguration.bind(this);
        this.toggleDeleteProductConfigurationModal = this.toggleDeleteProductConfigurationModal.bind(this);
        this.cleanCache = this.cleanCache.bind(this);
    }

    componentDidMount() {
        let { product } = this.state;
        let currentUser = this.userService.getLoggedInUser();
        let canDeleteProductionConfiguration = this.userService.hasPrivilege(currentUser, 'product-delete');
        this.setState({ canDeleteProductionConfiguration });

        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            product.id = searchParams.productID;
            this.getProduct(product);
        }
    }

    getProduct(product) {
        let { loading } = this.state;
        loading.product = true;
        this.setState({ loading });

        productService.getProduct(product.id).then(response => {
            loading.product = false;
            product = response.data;
            this.setState({ product, loading });
            this.getProductConfigurations(product);
        }).catch(error => {
            loading.product = false;
            this.setState({ loading });
            toast.error(handleErrorMessage(error));
        })
    }

    getProductConfigurations(product) {
        let { loading } = this.state;
        loading.configurations = true;
        this.setState({ loading });
        ProductConfigurationService.getProductConfigurations(product.id).then(response => {
            loading.configurations = false;
            let configurations = response.data;
            configurations.forEach(item => {
                item.uuid = 'configuration' + uuidv4();
            });
            this.setState({ configurations, loading });
        }).catch(error => {
            loading.configurations = false;
            this.setState({ loading });
            toast.error(handleErrorMessage(error));
        })
    }

    addNewItem() {
        let { configurations, product } = this.state;
        let item = {
            id: 0,
            productId: product.id,
            attributeLabel: "",
            attributeKey: "",
            attributeInputType: "text",
            parentConfigurationId: "",
            isHidden: false,
            isActive: true,
            uuid: 'configuration' + uuidv4()
        };
        configurations.push(item);
        this.setState({ configurations });
    }

    handleChange(value, key, index) {
        let { configurations } = this.state;
        configurations[index][key] = value;
        this.setState({ configurations });
    }

    saveProductConfiguration(item, index) {
        let { configurations, loading } = this.state;
        loading.save = index;
        this.setState({ loading });
        ProductConfigurationService.saveProductConfiguration(item).then(response => {
            configurations[index] = response.data;
            configurations[index].uuid = 'configuration' + uuidv4();
            loading.save = '';
            this.setState({ configurations, loading });
        }).catch(error => {
            loading.save = '';
            this.setState({ loading });
            toast.error(handleErrorMessage(error))
        })
    }

    openDeleteConfirmationModal(item) {
        if (item.id) {
            this.setState({ isOpenDeleteProductConfigurationModal: true, selectedItemProductConfiguration: item });
        }
        else {
            let { configurations } = this.state;
            let index = findIndex(configurations, "uuid", item.uuid);
            configurations.splice(index, 1);
            this.setState({ configurations });
        }
    }

    toggleDeleteProductConfigurationModal(data) {
        this.setState({ isOpenDeleteProductConfigurationModal: data });
    }

    handleDeleteProductConfiguration() {
        let { selectedItemProductConfiguration, configurations } = this.state;
        let index = findIndex(configurations, 'id', selectedItemProductConfiguration.id);

        this.setState({ deletingProductConfiguration: true });
        ProductConfigurationService.deleteProductConfiguration(selectedItemProductConfiguration.id).then(response => {
            if (response.status === 200) {
                toast.success("Deleted");
                configurations.splice(index, 1);
                this.setState({
                    configurations,
                    deletingProductConfiguration: false,
                    isOpenDeleteProductConfigurationModal: false
                });
            }
        }).catch(error => {
            this.setState({ deletingProductConfiguration: false });
            toast.error(handleErrorMessage(error));
        })
    }

    cleanCache() {
        let { product, loading } = this.state;
        loading.save = true;
        this.setState({ loading });
        productService.cleanProductConfigurationCache(product.id).then(response => {
            loading.save = false;
            this.setState({ loading });
            toast.success("Product Builder Cache Cleaned");
        }).catch(error => {
            loading.save = true;
            this.setState({ loading });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        })
    }
    render() {
        let { product, loading, configurations, isOpenDeleteProductConfigurationModal, canDeleteProductionConfiguration } = this.state;

        let headerOptions = configurations.filter(c => c.attributeHeader).map(c => {
            return { label: c.attributeHeader, value: c.attributeHeader }
        });

        headerOptions = getDistinct(headerOptions, 'value');

        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/inventory/products/all/list')}>Products</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Configuration</BreadcrumbItem>
                </Breadcrumb>


                <Card>
                    <CardHeader>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                {loading.product ? <Spinner color="primary" /> : <h5>{product.name}</h5>}
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    <Button color={"primary mr-2"}
                                        size={"sm"}
                                        onClick={this.cleanCache} disabled={loading.save}>
                                        {loading.save
                                            ? <Spinner size="sm"
                                                className={"mr-2"}
                                                style={{ color: "white" }} />
                                            : <i className="fa fa-eraser mr-2" aria-hidden="true"></i>}
                                     Clean Cache
                                    </Button>
                                    <Link
                                        className={"btn btn-sm btn-primary m-1"}
                                        to={"/inventory/product/configuration/stock/deduction?" + queryString.stringify({ productID: product.id })}>
                                        <i className="fa fa-calculator mr-2" aria-hidden="true" />Deductions
                                    </Link>

                                    <Link
                                        className={"btn btn-sm btn-primary m-1"}
                                        to={"/inventory/product/configuration/stock/formula"}>
                                        <i className="fa fa-calculator mr-2" aria-hidden="true" />Formulas
                                    </Link>
                                    <Link
                                        className={"btn btn-sm btn-primary m-1"}
                                        to={"/inventory/product/configuration/price/matrix?" + queryString.stringify({ productID: product.id })}>
                                        <i className="fa fa-th mr-2" aria-hidden="true" />Price Matrix
                                    </Link>
                                    <Link
                                        className={"btn btn-sm btn-primary m-1"}
                                        to={"/inventory/product/configuration/fabric?" + queryString.stringify({ productID: product.id })}>
                                        <i className="fa fa-cog mr-2" aria-hidden="true" />Configure Fabrics
                                    </Link>
                                    <Link
                                        className={"btn btn-sm btn-primary m-1"}
                                        to={"/inventory/product/configuration/matrix/convertor"}>
                                        <i className="fa fa-wrench mr-2" aria-hidden="true" />Matrix Converter
                                    </Link>
                                </div>

                            </Col>

                        </Row>

                    </CardHeader>
                    <CardBody>

                        {loading.configurations
                            ? <Spinner color="primary" />
                            : <div>
                                <Table responsive={true} striped bordered>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            {/* <th style={{minWidth: '200px'}}>Attribute-header
                                            <small>
                                                <div>It will be displayed as header(family name)</div>
                                            </small>
                                        </th>*/}
                                            <th style={{ minWidth: '200px' }}>Attribute-name
                                            <small>
                                                    <div>It will be displayed as label</div>
                                                </small>
                                            </th>
                                            <th style={{ minWidth: '200px' }}>Attribute-key
                                            <small>
                                                    <div>It should be unique for this product configuration</div>
                                                </small>
                                            </th>
                                            <th style={{ minWidth: '200px' }}>Parent configuration
                                            <small>
                                                    <div>Attribute-name</div>
                                                </small>
                                            </th>
                                            <th className={"text-center"}>Active</th>
                                            <th style={{ minWidth: '125px' }} className={"text-center"}>Options</th>
                                            <th style={{ minWidth: '200px' }} className={"text-center"}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(configurations || []).map((item, index) => {
                                            return <tr key={index}>
                                                <td>{index + 1}</td>
                                                {/*<td>
                                                <CreatableSelect
                                                    formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                                    onChange={(value) => this.handleChange(value.value, "attributeHeader", index, true)}
                                                    value={{
                                                        label: item.attributeHeader,
                                                        value: item.attributeHeader
                                                    }}
                                                    options={headerOptions}
                                                />

                                            </td>*/}
                                                <td>
                                                    <Input
                                                        type={"text"}
                                                        name={"attributeLabel"}
                                                        placeholder={"write attribute name here"}
                                                        value={item.attributeLabel}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type={"text"}
                                                        name={"attributeKey"}
                                                        placeholder={"write attribute key here"}
                                                        value={item.attributeKey}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <Input type={"select"}
                                                        name={"parentConfigurationId"}
                                                        value={item.parentConfigurationId}
                                                        onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                    >
                                                        <option value={""}>Select</option>
                                                        {(configurations || [])
                                                            .filter(option => (option.id && (option.id !== item.id)))
                                                            .map(option => {
                                                                return <option key={option.id}
                                                                    value={option.id}>{option.attributeLabel}</option>
                                                            }
                                                            )
                                                        }
                                                    </Input>

                                                </td>
                                                <td className={"text-center"}>
                                                    <a href={"javascript:void(0)"}
                                                        onClick={() => this.handleChange(!item.isActive, "isActive", index)}>
                                                        <i className={classnames("mt-2", "fa", "fa-lg", {
                                                            "fa-check-square-o": item.isActive,
                                                            "fa-square-o": !item.isActive,
                                                        }
                                                        )} />
                                                    </a>
                                                </td>
                                                <td className={"text-center"}>
                                                    {
                                                        item.id
                                                            ? <Link
                                                                to={"/inventory/product/configuration/option/?" + queryString.stringify({
                                                                    productID: product.id,
                                                                    configurationId: item.id
                                                                })}>
                                                                <Button color={"primary"} size={"sm"}>
                                                                    <i className="fa fa-cog mr-2" aria-hidden="true" />Options
                                                            </Button>
                                                            </Link>
                                                            : null
                                                    }
                                                </td>
                                                <td className={"text-center"}>
                                                    <Button color={"primary"}
                                                        size={"sm"}
                                                        className={"mr-2"}
                                                        disabled={loading.save === index}
                                                        onClick={() => this.saveProductConfiguration(item, index)}>
                                                        <i className="fa fa-floppy-o mr-2" aria-hidden="true" />
                                                        {loading.save === index ?

                                                            <Spinner size="sm"
                                                                style={{ color: "white" }} /> : (item.id ? "Update" : "Save")}
                                                    </Button>
                                                    {(canDeleteProductionConfiguration) ? <Button color='danger'
                                                        size={"sm"}
                                                        onClick={() => this.openDeleteConfirmationModal(item)}><i
                                                            className='fa fa-trash' /></Button>
                                                        : null}
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>

                                </Table>

                                <Button onClick={this.addNewItem} color={"primary"} className={"mt-2"}><i
                                    className="fa fa-plus mr-2" aria-hidden="true" /> Add</Button>
                            </div>
                        }
                    </CardBody>
                </Card>

                {isOpenDeleteProductConfigurationModal ?
                    <DeleteModal
                        header={'Are your sure you want to delete product configuration'}
                        isOpen={isOpenDeleteProductConfigurationModal}
                        toggle={this.toggleDeleteProductConfigurationModal}
                        handleDelete={this.handleDeleteProductConfiguration}
                        deleting={this.state.deletingProductConfiguration}
                    /> : null}

                <ToastContainer />
            </div>
        );
    }
}