import React, {Component} from 'react';
import {Alert, Button, Col, Row, Spinner} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import queryString from 'query-string';
import CustomerDashboardQuickLinks from '../../../../../components/CustomerDashboard/CustomerDashboardQuickLinks';
import UserService from '../../../../../services/UserService';
import { handleErrorMessage, getDateString } from '../../../../../services/CommonService';
import {downloadInvoice} from '../../../../../services/CommonFunctions';
import classnames from 'classnames';
import FileSaver from 'file-saver';
import OrderService from '../../../../../services/OrderService';
import SalesOrderService from '../../../../../services/sales/SalesOrderService';
import SalesOrderShareWrapper from '../../../../../components/sales/SalesOrderShareWrapper';
import salesOrderProductBuilderV1Service from '../../../../../services/sales/SalesOrderProductBuilderV1Service';

export default class SalesOrderSubmittedPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ordNum: "",
            currentUser: {},
            isQuote: false,
            downloadQuote: false,
            submitStrategy: "sync",
            isOpenShareDebtorInvoiceModal: false,
            documentType: "",
            retailVisible: false,
            order: {},
            emailMessage: {},
            isLoading: false,
            isDevelopment: false
        };
        this.userService = new UserService();
        this.orderService = new OrderService();
        this.salesOrderService = new SalesOrderService();
        this.redirect = this.redirect.bind(this);
        this.downloadQuote = this.downloadQuote.bind(this);
        this.downloadOrderSummary = this.downloadOrderSummary.bind(this);
        this.toggleShareDebtorInvoiceModal = this.toggleShareDebtorInvoiceModal.bind(this);
    }

    componentDidMount() {
        let { orderData, products, freight, packagingAndHandling, discountByProductCode, productMarkupByProductCode } = this.props.location.state;
        let currentUser = this.userService.getLoggedInUser();
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            let isTrueSet = searchParams.isQuote === 'true' ? true : false;
            this.setState({
                ordNum: searchParams.ordNum,
                submitStrategy: searchParams.submitStrategy,
                currentUser: currentUser,
                isQuote: isTrueSet,
                documentType: searchParams.documentType,
                poNumber: searchParams.poNumber
            });
        }
        this.setState({ isDevelopment: currentUser.isTestEnvironment, orderData, products, freight, packagingAndHandling, discountByProductCode, productMarkupByProductCode });
    }

    toggleShareDebtorInvoiceModal(data) {
        this.setState({isOpenShareDebtorInvoiceModal: data});
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.search) {
            let searchParams = queryString.parse(nextProps.location.search);
            if (this.state.ordNum !== searchParams.ordNum || this.state.submitStrategy !== searchParams.submitStrategy || this.state.isQuote !== searchParams.isQuote || this.state.poNumber !== searchParams.poNumber || this.state.documentType !== searchParams.documentType) {
                let isTrueSet = searchParams.isQuote === 'true' ? true : false;
                this.setState({
                    ordNum: searchParams.ordNum,
                    submitStrategy: searchParams.submitStrategy,
                    isQuote: isTrueSet,
                    documentType: searchParams.documentType,
                    poNumber: searchParams.poNumber
                });
            }
        }
    }

    downloadOrderSummary(type, ordNum) {
        this.setState({ downloadQuote: true });
        let { orderData, products, freight, packagingAndHandling, discountByProductCode, productMarkupByProductCode } = this.state;
        let order = orderData;
        let request = {
            accountID: order.accountID,
            email: order.email,
            delCompany: order.delCompany,
            salesOrderItems: [],
            retailFirstName: order.retailFirstName,
            retailEmail: order.retailEmail,
            retailPhone: order.retailPhone
        };
        let formError = {
            isValid: true,
            errors: []
        };
        (products || []).forEach(product => {
            salesOrderProductBuilderV1Service.prepareSalesOrderItem(order, product, packagingAndHandling, discountByProductCode, request, formError.errors, productMarkupByProductCode);
        });
        let customerParams = {
            "ordDate": getDateString(new Date(), "YYYY-MM-DD HH:mm:ss"),
            "accountID": order.accountID,
            "customerName": order.retailFirstName,
            "storeEmail": order.retailEmail,
            "company": order.delCompany
        };
        let item, items = [];
        request.salesOrderItems.forEach(soi => {
            salesOrderProductBuilderV1Service.prepareCustomerSalesSummaryReportJson(soi, item, customerParams, items);
        });
        let fileDownloadWrapperRequest = { items };
        this.orderService.downloadFile(type, 0, "", fileDownloadWrapperRequest).then(response => {
            if (response.data) {
                let fileName = ordNum + " " + type + ".pdf";
                FileSaver.saveAs(response.data, fileName);
            }
            this.setState({ downloadQuote: false });
        }).catch(error => {
            console.error(error);
            this.setState({ downloadQuote: false });
            toast.error(handleErrorMessage(error));
        });
    }

    downloadQuote(ordNum) {
        this.setState({downloadQuote: true});

        downloadInvoice(ordNum, "quote", result => {     // here result is the callback value returned from common funct.
            if (result.status !== 200) {
                toast.error(handleErrorMessage(result));
                this.setState({downloadQuote: false});
            } else {
                this.setState({downloadQuote: false});
            }
        });
    }

    redirect(key) {
        let url = "";
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.delete("ordNum");
        searchParams.delete("submitStrategy");
        searchParams.delete("isQuote");
        searchParams.delete("documentType");
        searchParams.delete("poNumber");
        switch (key) {
            case "Product Builder":
                url = "/sales/order/create/product-builder/v1";
                break;
            case "My Orders":
                url = "/customer/order";
                break;
            case "Order Gateway":
                url = "/sales/ordergateway?";
                searchParams.set("selectedOrderNum", this.state.ordNum);
                if (!this.state.isQuote) {
                    searchParams.set("orderType", 'SUB');
                } else {
                    searchParams.set("orderType", 'QUOTE');
                }

                break;
            default:
                break;
        }
        this.props.history.push(url + searchParams.toString())
    }


    render() {
        let {isLoading, ordNum, poNumber,submitStrategy, currentUser, isQuote, downloadQuote, isOpenShareDebtorInvoiceModal, retailVisible, documentType, isDevelopment} = this.state;
        let btnText = "Place Another Order";
        return (
            <div>
                {
                    currentUser.isExternalUser
                        ? <div>
                            <CustomerDashboardQuickLinks size={"sm"}/>
                            <hr/>
                        </div>
                        : null
                }

                {isLoading ? <Spinner color={"primary"}/>
                    :
                    <Alert color="success">
                        <Row className={"align-items-center"}>
                            <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                                <div className="text-center">
                                    <i className={classnames("fa", "fa-3x", " mr-2", {
                                            "fa-check-circle": submitStrategy === "sync",
                                            "fa-info-circle": submitStrategy === "async"
                                        }
                                    )} aria-hidden="true"/>
                                </div>

                            </Col>
                            <Col xl={11} lg={11} md={11} sm={11} xs={11}>
                                <h5>
                                    {
                                        (!isQuote)  // means Order
                                            ?
                                            (submitStrategy === "sync")
                                                ? "Order submitted"
                                                : "Order submission is in progress"
                                            :
                                            (submitStrategy === "sync")
                                                ? "Quote submitted"
                                                : "Quote submission is in progress"
                                    }

                                </h5>
                                {
                                    (!isQuote)  // means Order
                                        ?
                                        (submitStrategy === "sync") && (!currentUser.isExternalUser && currentUser.menu && currentUser.menu.role!=="InternalUser") 
                                            ?
                                                <>
                                            <p className="mb-0">Your order #<strong>{ordNum}</strong> has been
                                                successfully
                                                submitted.</p>
                                            <p className="mb-0">Your order has been submitted with order number
                                                #<strong>{ordNum}</strong> and is being processed. Customer Service Team
                                                will review the information provided.</p>
                                                </>
                                                :
                                                <p className="mb-0">Your order has been submitted with PO Number 
                                                   <strong> {poNumber}</strong> and is being reviewed by our Customer Service Team.</p>
                                        :
                                        (submitStrategy === "sync")
                                            ?
                                            <p className="mb-0">Quote generated successfully. <br/> Your Quote reference
                                                is
                                                #<strong>{ordNum}</strong>.<br/>
                                                This will be valid for 30 days, in which you can convert this to an
                                                order
                                                <br/>for processing via the Quotes tab in SALLY.</p>
                                            :
                                            <p className="mb-0">Your Quote has been submitted with order number
                                                #<strong>{ordNum}</strong> and is being processed.
                                                Customer Service Team will review the information provided.</p>
                                }
                                <hr/>
                                <div>
                                    <Button size={"sm"} color={"primary"} className={"mr-2"}
                                            onClick={() => this.redirect("Product Builder")}>
                                        <i className="fa fa-shopping-cart mr-2" aria-hidden="true"/>
                                        {btnText}
                                    </Button>
                                    {
                                        (isQuote)  // means Quote
                                            ?
                                            <Button title="Download Customer Order Summary"
                                                color="primary"
                                                size="sm"
                                                disabled={downloadQuote}
                                                onClick={() => this.downloadOrderSummary("Customer Order Summary", ordNum)}>
                                                {
                                                    downloadQuote
                                                        ? <Spinner size="sm" className="mr-2" />
                                                        : <i className="fa fa-download mr-2" />
                                                }
                                                {
                                                    downloadQuote
                                                        ? "Downloading Customer Order Summary"
                                                        : "Download Customer Order Summary"
                                                }
                                            </Button>
                                            : null
                                    }
                                    {/*<Button color='primary' size="sm ml-2"
                                            onClick={() => this.toggleShareDebtorInvoiceModal(true)}>
                                        <i className="fa fa-share mr-2"/>Share Order Summary
                                    </Button>*/}

                                    <Button size={"sm"} color={"link"}
                                            onClick={() => this.redirect(currentUser.isExternalUser ? "My Orders" : "Order Gateway")}>
                                        <i className="fa fa-external-link mr-2" aria-hidden="true"/>
                                        {currentUser.isExternalUser ? "My Orders" : "Order Gateway"}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Alert>
                }

                {
                    isOpenShareDebtorInvoiceModal ?
                        <SalesOrderShareWrapper
                            isOpen={isOpenShareDebtorInvoiceModal}
                            toggle={this.toggleShareDebtorInvoiceModal}
                            documentType={documentType}
                            ordNum={ordNum}
                        />
                        : null
                }
                <ToastContainer/>
            </div>
        );
    }
}
