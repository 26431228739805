import React, { Component } from "react";
import { Input, Row, Col, Label } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import SearchDebtorAcccount from './SearchDebtorAcccount';
import classnames from 'classnames';
import { BUNNINGS_STORE_ACCOUNT_IDs } from "../../store/AppConstants";

const maxDate = moment().add(2, 'years').toDate();
const minDate = moment().subtract(5, 'years').toDate();

export default class SearchGatewayOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(value, key) {
        this.props.handleChange(value, key);
    }

    render() {
        let { filterRequest, currentUser, type, tab } = this.props;
        return (
            <Row>
                {tab === "quote" ?
                    <Col xl={2} lg={2} md={4} sm={12} xs={12}>
                        <Label>Mobile Number</Label>
                        <div className="text-center">
                            <Input type="search"
                                placeholder="Search..."
                                name="retailPhone"
                                value={filterRequest.retailPhone}
                                onChange={(e) => this.props.handleChange(e.target.value, "retailPhone")} />
                        </div>
                    </Col>
                    : null}
                {["quote", "QUOTE"].includes(tab) ? null
                    : <Col xl={2} lg={2} md={4} sm={12} xs={12}>
                        <Label>{type === "gatewayOrder" ? "Customer Order No." : "PO Number"}</Label>
                        <div className="text-center">
                            <Input type="search"
                                placeholder="Search..."
                                name="custOrdNum"
                                value={filterRequest.custOrdNum}
                                onChange={(e) => this.props.handleChange(e.target.value, "custOrdNum")} />
                        </div>
                    </Col>
                }
                {["quote", "QUOTE"].includes(tab) ?
                    <Col xl={2} lg={2} md={4} sm={12} xs={12}>
                        <Label>COS Number</Label>
                        <div className="text-center">
                            <Input type="search"
                                placeholder="Search..."
                                name="cosNumber"
                                value={filterRequest.cosNumber}
                                onChange={(e) => this.props.handleChange(e.target.value, "cosNumber")} />
                        </div>
                    </Col>
                    : null
                }
                {tab === "quote" ?
                    <Col xl={2} lg={2} md={4} sm={12} xs={12}>
                        <Label>Customer Name</Label>
                        <div className="text-center">
                            <Input type="search"
                                placeholder="Search..."
                                name="retailFirstName"
                                value={filterRequest.retailFirstName}
                                onChange={(e) => this.props.handleChange(e.target.value, "retailFirstName")} />
                        </div>
                    </Col>
                    : null
                }
                {currentUser && currentUser.menu && currentUser.menu.role !== 'ExternalUser' ?
                    <Col xl={2} lg={2} md={4} sm={12} xs={12}>
                        <Label>{type === "gatewayOrder" ? (tab === "QUOTE" ? "Quote No." : "Order No.") : "SHP Order Number"}</Label>
                        <div className="text-center">
                            <Input type="search"
                                placeholder="Search..."
                                name="ordNum"
                                value={filterRequest.ordNum}
                                onChange={(e) => this.props.handleChange(e.target.value, "ordNum")} />
                        </div>
                    </Col>
                    : null}
                {currentUser && currentUser.menu && currentUser.menu.role !== 'ExternalUser' ?
                    <Col xl={2} lg={2} md={4} sm={12} xs={12}>
                        <Label>Account ID</Label>
                        <SearchDebtorAcccount
                            handleAccountChange={(selectedAccountID) => this.handleChange(selectedAccountID, 'accountID')}
                            selectedAccountID={filterRequest.accountID}
                            defaultAccountID={filterRequest.accountID}
                            includeChildren={true}
                            excludeClosedandInactive={false}
                            parentAccountIDs={BUNNINGS_STORE_ACCOUNT_IDs}
                        />
                    </Col>
                    : null}
                <Col xl={2} lg={2} md={4} sm={12} xs={12}>
                    <Label> <i className="fa fa-calendar" aria-hidden="true" />&nbsp;Start Date </Label>
                    <div>
                        <DatePicker
                            className="form-control form-control"
                            selected={filterRequest.startDate}
                            onChange={date => this.props.handleChange(date, "startDate")}
                            selectsStart
                            startDate={filterRequest.startDate}
                            endDate={filterRequest.endDate}
                            dateFormat="dd/MM/yyyy"
                            isClearable
                            placeholderText="Start Date"
                            showMonthDropdown
                            showYearDropdown
                            maxDate={maxDate}
                            minDate={minDate}
                            scrollableYearDropdown
                            dropdownMode="select"
                            withPortal
                        />
                    </div>
                </Col>
                <Col xl={2} lg={2} md={4} sm={12} xs={12}>
                    <Label> <i className="fa fa-calendar" aria-hidden="true" />&nbsp;End Date</Label>
                    <div className="text-right">
                        <DatePicker
                            className="form-control form-control"
                            selected={filterRequest.endDate}
                            onChange={date => this.props.handleChange(date, "endDate")}
                            selectsEnd
                            startDate={filterRequest.startDate}
                            endDate={filterRequest.endDate}
                            dateFormat="dd/MM/yyyy"
                            isClearable
                            placeholderText="End Date"
                            showMonthDropdown
                            showYearDropdown
                            maxDate={maxDate}
                            minDate={minDate}
                            scrollableYearDropdown
                            dropdownMode="select"
                            withPortal
                        />
                    </div>
                </Col>
                {currentUser && currentUser.menu && currentUser.menu.role !== 'ExternalUser' ?
                    <Col xl={2} lg={2} md={4} sm={12} xs={12} className="mt-4">
                        <span>
                            <a href="javascript:void(0)"
                                onClick={() => this.handleChange(!filterRequest.isBunning, "isBunning")}>
                                <i className={classnames("mt-2", "fa", "fa-lg", {
                                    "fa-check-square-o": filterRequest.isBunning,
                                    "fa-square-o": !filterRequest.isBunning,
                                }
                                )} />
                            </a>&nbsp;Is Bunnings
                        </span>
                    </Col> : null}
            </Row>
        )
    }
}