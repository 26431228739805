import React, { Component } from 'react';
import { Badge, Button, Input, Table, Row } from 'reactstrap';
import {findIndex, scrollTo} from '../../../../../../services/CommonService';
import {camelCase, cloneDeep, isEmpty} from 'lodash';
import classnames from 'classnames';
import rollerBlindSingleUtil from './RollerBlindSingleUtil';
import NumberFormat from "react-number-format";
import RollerBlindSingleBOMModal from "./RollerBlindSingleBOMModal";
import RollerBlindSingleConsolidatedBOMModal from "./RollerBlindSingleConsolidatedBOMModal";
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import Paragraph from "../../../../../tools/Paragraph";

export default class RollerBlindSingle extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workingBOMModal: {
                isOpen: false,
                itemIndex: -1
            },
            isOpenConsolidatedBOMModal: false,
            isHiddenExtendedColumn: true
        };

        this.handleItemAction = this.handleItemAction.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.updateItemConfigurationOptions = this.updateItemConfigurationOptions.bind(this);
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
        this.toggleConsolidatedBOMModal = this.toggleConsolidatedBOMModal.bind(this);
        this.toggleExtendedColumn = this.toggleExtendedColumn.bind(this);
    }

    componentDidMount() {
        if (!isEmpty(this.props.product.savedItems)) {
            this.handleItemAction("init_savedItem", -1, null);
        }
    }

    toggleBOMModal(change, itemIndex) {
        let {workingBOMModal} = this.state;
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        if (change) {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = itemIndex;
        } else {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = -1;
        }

        if (workingBOMModal.isOpen && workingBOMModal.itemIndex > -1) {
            product = rollerBlindSingleUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode, true);
            this.props.handleChange("product", product);
        }

        this.setState({workingBOMModal});
    }

    toggleConsolidatedBOMModal(change) {
        this.setState({isOpenConsolidatedBOMModal: change});
    }

    toggleExtendedColumn(isHiddenExtendedColumn) {
        this.setState({ isHiddenExtendedColumn });
    }

    handleItemAction(key, itemIndex, context) {
        let {product, products, order, packagingAndHandling, discountByProductCode} = this.props;
        let item;

        switch (key) {
            case "new":
                item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                product.items.push(item); //insert item
                itemIndex = product.items.length - 1; // get item index
                product = this.updateConfiguration(product, itemIndex, null, order); //update config for item
                product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                scrollTo("product-" + product.productGroupID + "-item-table-responsive-wrapper", "left");
                break;
            case "clone":
                product.items.push(cloneDeep(product.items[itemIndex]));
                product.items.forEach((item, itemIndex) => {
                    product = rollerBlindSingleUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "delete":
                product.items.splice(itemIndex, 1);
                product.items.forEach((item, itemIndex) => {
                    product = rollerBlindSingleUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "init_savedItem":
                product.savedItems.forEach(si => {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(si.id);
                    product.items.push(item); //insert item
                    itemIndex = product.items.length - 1; // get item index
                    product = this.updateConfiguration(product, itemIndex, si, order); //update config for item
                    product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                    product = rollerBlindSingleUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode, true);
                });
                product.savedItems = [];
                break;
        }
        if (product.items[itemIndex]) {
            product = rollerBlindSingleUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode, false);
        }
        product.init = true;
        this.props.handleChange("product", product);
    }

    updateConfiguration(product, itemIndex, context, order) {

        let {configurations, fabrics} = product.builder;

        let optionIndex, configurationIndex;
        product.items[itemIndex].configuration = {};
        product.items[itemIndex].isValid = true;

        //quantity
        configurationIndex = findIndex(configurations, "attributeKey", "quantity");
        product.items[itemIndex].configuration.quantity = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.quantity.min = 1;
        product.items[itemIndex].configuration.quantity.max = 999;
        product.items[itemIndex].configuration.quantity.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.quantity.selected.value = context.salesOrderItemRollerBlindSingle.quantity;
        } else {
            product.items[itemIndex].configuration.quantity.selected.value = 1;
        }
        product.items[itemIndex].configuration.quantity.formError = {isValid: true, message: "", dom: null,};

        //width
        configurationIndex = findIndex(configurations, "attributeKey", "width");
        product.items[itemIndex].configuration.width = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.width.selected = {
            value: 0,
            finishWidth: 0
        };

        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.width.selected.value = parseInt(context.salesOrderItemRollerBlindSingle.width);
        } else {
            product.items[itemIndex].configuration.width.selected.value = 0;
        }
        product.items[itemIndex].configuration.width.formError = {
            isValid: true,
            message: "",
            dom: null,
        };


        //drop
        configurationIndex = findIndex(configurations, "attributeKey", "drop");
        product.items[itemIndex].configuration.drop = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.drop.selected = {
            value: 0,
            finishDrop: 0,
            isSelectable: false
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.drop.selected.value = parseInt(context.salesOrderItemRollerBlindSingle.drop);
        } else {
            product.items[itemIndex].configuration.drop.selected.value = 0;
        }
        product.items[itemIndex].configuration.drop.formError = {
            isValid: true,
            message: "",
            dom: null,
        };

        product.items[itemIndex].configuration.set = {
            selected: {
                value: {
                    widthSet: {},
                    dropSet: {},
                    priceMatrix: {}
                },
                stocks: []
            }
        };

        //model
        configurationIndex = findIndex(configurations, "attributeKey", "model");
        product.items[itemIndex].configuration.model = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.model.selected = {};
        product.items[itemIndex].configuration.model.finalOptions = product.items[itemIndex].configuration.model.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.model.finalOptions, 'optionKey', context.salesOrderItemRollerBlindSingle.model);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.model.selected.value = product.items[itemIndex].configuration.model.finalOptions[optionIndex];
        product.items[itemIndex].configuration.model.selected.dropdownValue = product.items[itemIndex].configuration.model.selected.value.optionKey;
        product.items[itemIndex].configuration.model.formError = {isValid: true, message: "", dom: null,};


        //mount
        configurationIndex = findIndex(configurations, "attributeKey", "mount");
        product.items[itemIndex].configuration.mount = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.mount.selected = {};
        product.items[itemIndex].configuration.mount.finalOptions = product.items[itemIndex].configuration.mount.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.mount.finalOptions, 'optionKey', context.salesOrderItemRollerBlindSingle.mount);
        }
        optionIndex = optionIndex > -1 ? optionIndex : findIndex(product.items[itemIndex].configuration.mount.finalOptions, 'optionKey', "Inside Mount");
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.mount.selected.value = product.items[itemIndex].configuration.mount.finalOptions[optionIndex];
        product.items[itemIndex].configuration.mount.selected.dropdownValue = product.items[itemIndex].configuration.mount.selected.value.optionKey;
        product.items[itemIndex].configuration.mount.formError = {isValid: true, message: "", dom: null,};


        //measurementType
        configurationIndex = findIndex(configurations, "attributeKey", "measurementType");
        product.items[itemIndex].configuration.measurementType = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.measurementType.selected = {};
        product.items[itemIndex].configuration.measurementType.finalOptions = product.items[itemIndex].configuration.measurementType.options;
        optionIndex = -1;


        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.measurementType.finalOptions, 'optionKey', context.salesOrderItemRollerBlindSingle.measurementType);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.measurementType.selected.value = product.items[itemIndex].configuration.measurementType.finalOptions[optionIndex];
        product.items[itemIndex].configuration.measurementType.selected.dropdownValue = product.items[itemIndex].configuration.measurementType.selected.value.optionKey;
        product.items[itemIndex].configuration.measurementType.formError = {isValid: true, message: "", dom: null,};


        //bom
        configurationIndex = findIndex(configurations, "attributeKey", "bom");
        product.items[itemIndex].configuration.bom = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bom.selected = {};

        //productionCalculation
        configurationIndex = findIndex(configurations, "attributeKey", "productionCalculation");
        product.items[itemIndex].configuration.productionCalculation = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.productionCalculation.selected = {

            fabricCutDrop: 0,
            fabricCutWidth: 0,
        };

        //deduction
        product.items[itemIndex].configuration.deduction = {
            deductionRules: [],
            selected: {
                value: {
                    deduction: 0
                }
            }
        };

        //colour
        configurationIndex = findIndex(configurations, "attributeKey", "colour");
        product.items[itemIndex].configuration.colour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.colour.selected = {};
        product.items[itemIndex].configuration.colour.finalOptions = product.items[itemIndex].configuration.colour.options;
        optionIndex = -1;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.colour.finalOptions, 'optionKey', context.salesOrderItemRollerBlindSingle.colour);
        }
        optionIndex = optionIndex > -1 ? optionIndex : 0;
        product.items[itemIndex].configuration.colour.selected.value = product.items[itemIndex].configuration.colour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.colour.selected.dropdownValue = product.items[itemIndex].configuration.colour.selected.value.optionKey;
        product.items[itemIndex].configuration.colour.formError = {isValid: true, message: "", dom: null,};


        //fineline
        configurationIndex = findIndex(configurations, "attributeKey", "fineline");
        product.items[itemIndex].configuration.fineline = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.fineline.selected = {};
        product.items[itemIndex].configuration.fineline.selected.value = {};
        product.items[itemIndex].configuration.fineline.selected.dropdownValue = product.items[itemIndex].configuration.fineline.selected.value.optionKey;
        product.items[itemIndex].configuration.fineline.formError = {isValid: true, message: "", dom: null,};


        //offRange
        configurationIndex = findIndex(configurations, "attributeKey", "offRange");
        product.items[itemIndex].configuration.offRange = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.offRange.selected = {};
        product.items[itemIndex].configuration.offRange.selected.value = {};
        product.items[itemIndex].configuration.offRange.selected.dropdownValue = product.items[itemIndex].configuration.offRange.selected.value.optionKey;
        product.items[itemIndex].configuration.offRange.formError = { isValid: true, message: "", dom: null, };


        product = this.updateItemConfigurationOptions("model", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("colour", product, itemIndex, order);
        product = this.updateItemConfigurationOptions("drop", product, itemIndex, order);

        product = rollerBlindSingleUtil.updateRules("quantity", product, itemIndex, order);
        product = rollerBlindSingleUtil.updateRules("width", product, itemIndex, order);
        product = rollerBlindSingleUtil.updateRules("drop", product, itemIndex, order);

        /*product = rollerBlindSingleUtil.updateFormError("quantity", product, itemIndex, order);
        product = rollerBlindSingleUtil.updateFormError("width", product, itemIndex, order);
        product = rollerBlindSingleUtil.updateFormError("drop", product, itemIndex, order);*/

        return product;
    }

    updateItemConfigurationOptions(key, product, itemIndex, context) {
        let optionIndex = -1, condition1 = "";
        if (key) {
            switch (key) {
                case "model":
                    product.items[itemIndex].configuration.model.finalOptions = product.items[itemIndex].configuration.model.options;
                    optionIndex = -1;
                    if (!isEmpty(product.items[itemIndex].configuration.model.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.model.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.model.finalOptions, 'optionKey', product.items[itemIndex].configuration.model.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.model.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.model.selected.value = product.items[itemIndex].configuration.model.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.model.selected.dropdownValue = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.model.selected.value = {};
                        product.items[itemIndex].configuration.model.selected.dropdownValue = "";
                    }
                    break;
                case "colour":
                    optionIndex = -1;
                    condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                    product.items[itemIndex].configuration.colour.finalOptions = product.items[itemIndex].configuration.colour.options.filter(o =>
                        (o.condition1 ? o.condition1 === condition1 : true)
                    );
                    if (!isEmpty(product.items[itemIndex].configuration.colour.finalOptions)) {
                        if (!isEmpty(product.items[itemIndex].configuration.colour.selected.value)) {
                            optionIndex = findIndex(product.items[itemIndex].configuration.colour.finalOptions, 'optionKey', product.items[itemIndex].configuration.colour.selected.value.optionKey);
                        }
                        if (optionIndex === -1) {
                            if (!isEmpty(product.items[itemIndex].configuration.colour.finalOptions)) {
                                optionIndex = 0;
                            }
                        }
                    }
                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.colour.selected.value = product.items[itemIndex].configuration.colour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.colour.selected.dropdownValue = product.items[itemIndex].configuration.colour.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.colour.selected.value = {};
                        product.items[itemIndex].configuration.colour.selected.dropdownValue = "";
                    }
                    break;
                case "drop":
                    product.items[itemIndex].configuration.drop.selected.value =
                        product.items[itemIndex].configuration.model.selected.value.secondaryAttributeMax;
                    break;
                default:
                    break;
            }
        }
        return product;
    }

    handleChange(change, key, itemIndex, isComponentUpdateRequired) {
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        let optionIndex = -1;
        switch (key) {
            case "quantity":
                if (change > -1 && change < 1000) {
                    product.items[itemIndex].configuration.quantity.selected.value = change;
                }
                product = rollerBlindSingleUtil.updateFormError("quantity", product, itemIndex);
                break;
            case "width":
                product.items[itemIndex].configuration.width.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("width", product, itemIndex, order);
                product = rollerBlindSingleUtil.updateFormError("width", product, itemIndex);
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.selected.value = parseInt(change);
                product = this.updateItemConfigurationOptions("chainLength", product, itemIndex, {
                    ...order,
                    isPreservePreviousSelectionRequired: false
                });
                product = rollerBlindSingleUtil.updateFormError("drop", product, itemIndex);
                break;
            case "model":
                optionIndex = findIndex(product.items[itemIndex].configuration.model.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.model.selected.value = product.items[itemIndex].configuration.model.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.model.selected.dropdownValue = product.items[itemIndex].configuration.model.selected.value.optionKey;
                }
                product = this.updateItemConfigurationOptions("colour", product, itemIndex, order);
                product = this.updateItemConfigurationOptions("drop", product, itemIndex, order);

                product = rollerBlindSingleUtil.updateRules("width", product, itemIndex, order);
                product = rollerBlindSingleUtil.updateRules("drop", product, itemIndex, order);

                product = rollerBlindSingleUtil.updateFormError("width", product, itemIndex, order);
                product = rollerBlindSingleUtil.updateFormError("drop", product, itemIndex, order);

                break;
            case "mount":
                optionIndex = findIndex(product.items[itemIndex].configuration.mount.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.mount.selected.value = product.items[itemIndex].configuration.mount.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.mount.selected.dropdownValue = product.items[itemIndex].configuration.mount.selected.value.optionKey;
                }
                break;
            case "measurementType":
                optionIndex = findIndex(product.items[itemIndex].configuration.measurementType.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.measurementType.selected.value = product.items[itemIndex].configuration.measurementType.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.measurementType.selected.dropdownValue = product.items[itemIndex].configuration.measurementType.selected.value.optionKey;
                }
                break;
            case "colour":
                optionIndex = findIndex(product.items[itemIndex].configuration.colour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.colour.selected.value = product.items[itemIndex].configuration.colour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.colour.selected.dropdownValue = product.items[itemIndex].configuration.colour.selected.value.optionKey;
                }
                product = rollerBlindSingleUtil.updateRules("width", product, itemIndex, order);
                product = rollerBlindSingleUtil.updateRules("drop", product, itemIndex, order);
                break;
            default:
                break;
        }
        if (isComponentUpdateRequired) {
            //immediately update component
            product = rollerBlindSingleUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode, true);

            this.props.handleChange("product", product);
        } else {
            return product;
        }
    }

    render() {
        let { workingBOMModal, isOpenConsolidatedBOMModal, isHiddenExtendedColumn } = this.state;
        let { product, order, currentUser } = this.props;
        let items = product.items;

        return (<div>
                <div>
                    {(items && items.length > 0)
                        ?
                        <div className="table-responsive"
                             id={"product-" + product.productGroupID + "-item-table-responsive-wrapper"}>
                            <Table bordered style={{minHeight: 400}}>
                                <thead>
                                <tr>
                                    <th className="text-center" style={{minWidth: 45}}>
                                        #
                                    </th>
                                    <th className="text-center" style={{minWidth: 100}}>
                                        Quantity
                                    </th>
                                    <th className="text-center" style={{minWidth: 200}}>
                                        Model
                                    </th>
                                    <th className="text-center" style={{minWidth: 180}}>
                                        Colour
                                    </th>
                                    <th className="text-center" style={{minWidth: 100}}>
                                        <div>Bracket to</div>
                                        <div>Bracket</div>
                                        <div>Width(mm)</div>
                                    </th>
                                    <th className="text-center" style={{minWidth: 100}}>
                                        <div>Drop(mm)</div>
                                    </th>
                                    {/*<th className="text-center" style={{minWidth: 205}}>
                                        Measure Type
                                    </th>*/}
                                    <th className="text-center" style={{minWidth: 175}}>
                                        Mount
                                    </th>
                                    <th className="text-center" style={{minWidth: 225}}>
                                        {
                                            currentUser.isExternalUser
                                                ? <div>Fineline</div>
                                                : <div>Ordered</div>
                                        }
                                        <div>Items</div>
                                    </th>
                                    {!isHiddenExtendedColumn ? 
                                        <th className="text-center" style={{ minWidth: 225 }}>
                                            <div>Off Range Item</div>
                                            <div>Code</div>
                                        </th>
                                        : null
                                    }
                                    {!isHiddenExtendedColumn ?
                                        <th className="text-center" style={{ minWidth: 225 }}>
                                            <div>Off Range Price</div>
                                        </th>
                                        : null
                                    }
                                    <th className="text-center" style={{ minWidth: 130 }}>
                                        <div>Fineline</div>
                                        <div>Cut Fee</div>
                                    </th>
                                    {
                                        !currentUser.isExternalUser
                                            ? <th className="text-center" style={{minWidth: 50}}>
                                                Unit Price
                                            </th>
                                            : null
                                    }
                                    {
                                        !currentUser.isExternalUser
                                            ? <th className="text-center" style={{minWidth: 50}}>
                                                Price
                                            </th>
                                            : null
                                    }
                                    <th className="text-center" style={{minWidth: 125}}>
                                        Action
                                    </th>
                                    {
                                        !currentUser.isExternalUser
                                            ? <th className="text-center" style={{minWidth: 90}}>
                                                <Button color={"link"}
                                                        onClick={() => this.toggleConsolidatedBOMModal(true)}>BOM</Button>
                                            </th>
                                            : null
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (items || []).map((item, itemIndex) => {
                                            let uniqueId = "product-" + product.productGroupID + "-item-" + itemIndex;
                                            return <tr key={uniqueId}
                                                       id={uniqueId}
                                                       className={classnames({"table-danger": !item.isValid})}>
                                                <td className="align-middle text-center">
                                                    <span>{itemIndex + 1}</span>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Input type="number" name="quantity"
                                                           invalid={!item.configuration.quantity.formError.isValid}
                                                           value={item.configuration.quantity.selected.value}
                                                           min={item.configuration.quantity.min}
                                                           max={item.configuration.quantity.max}
                                                           onChange={(e) => this.handleChange(e.target.value, "quantity", itemIndex, true)}
                                                           onFocus={(event) => event.target.select()}
                                                           placeholder="quantity"/>

                                                    {
                                                        item.configuration.quantity.formError.isValid
                                                            ? null
                                                            : <Badge color="danger">
                                                                {item.configuration.quantity.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Input type="select" name="model"
                                                           value={item.configuration.model.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "model", itemIndex, true)}>
                                                        <option value={""} disabled={true}>Select</option>
                                                        {
                                                            (item.configuration.model.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle text-center">
                                                    {(item.configuration.colour.finalOptions || []).length > 0 ?
                                                        <div>
                                                            <Input type="select" name="colour"
                                                                   value={item.configuration.colour.selected.dropdownValue}
                                                                   onChange={(e) => this.handleChange(e.target.value, "colour", itemIndex, true)}>
                                                                <option value={""} disabled>Select</option>

                                                                {
                                                                    (item.configuration.colour.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                        </div> :
                                                        <div>N/A</div>
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Input type="number" name="width"
                                                           invalid={!item.configuration.width.formError.isValid}
                                                           value={item.configuration.width.selected.value}
                                                           min={item.configuration.width.min}
                                                           max={item.configuration.width.max}
                                                           onChange={(e) => this.handleChange(e.target.value, "width", itemIndex, true)}
                                                           onFocus={(event) => event.target.select()}
                                                           placeholder="width"/>
                                                    {
                                                        item.configuration.width.formError.isValid
                                                            ? null
                                                            : <Badge color={"danger"}>
                                                                {item.configuration.width.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    <div>
                                                        <Input type="number" name="drop"
                                                               invalid={!item.configuration.drop.formError.isValid}
                                                               value={item.configuration.drop.selected.value}
                                                               min={item.configuration.drop.min}
                                                               max={item.configuration.drop.max}
                                                            // onChange={(e) => this.handleChange(e.target.value, "drop", itemIndex, true)}
                                                               onFocus={(event) => event.target.select()}
                                                               disabled={!item.configuration.drop.selected.isSelectable}
                                                               placeholder="drop"/>
                                                        {
                                                            item.configuration.drop.formError.isValid
                                                                ? null
                                                                : <Badge color={"danger"}>
                                                                    {item.configuration.drop.formError.message}
                                                                </Badge>
                                                        }
                                                    </div>
                                                </td>
                                                {/* <td className="align-middle text-center">
                                                    {(item.configuration.measurementType.finalOptions || []).length > 1 ?
                                                        <div>
                                                            <Input type="select" name="measurementType"
                                                                   value={item.configuration.measurementType.selected.dropdownValue}
                                                                   onChange={(e) => this.handleChange(e.target.value, "measurementType", itemIndex, true)}>
                                                                <option value={""} disabled>Select</option>
                                                                {
                                                                    (item.configuration.measurementType.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                        </div> :
                                                        <div>N/A</div>
                                                    }
                                                </td>*/}
                                                <td className="align-middle text-center">
                                                    {(item.configuration.mount.finalOptions || []).length > 1 ?
                                                        <div>
                                                            <Input type="select" name="mount"
                                                                   value={item.configuration.mount.selected.dropdownValue}
                                                                   onChange={(e) => this.handleChange(e.target.value, "mount", itemIndex, true)}>
                                                                <option value={""} disabled>Select</option>

                                                                {
                                                                    (item.configuration.mount.finalOptions || []).map((o, oIndex) => {
                                                                        return <option
                                                                            key={"item" + itemIndex + "-option" + oIndex}
                                                                            value={o.optionKey}>{o.optionLabel}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                        </div> :
                                                        <div>N/A</div>
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    {
                                                        currentUser.isExternalUser
                                                            ?   <Paragraph
                                                                text={product.items[itemIndex].configuration.fineline.selected.value.product.stockItemCd}
                                                                isCopyable={true}
                                                                innerTag={"strong"}
                                                                innerTagColour={""}
                                                                outerTag={"div"}
                                                            />
                                                            : <div>
                                                                <Paragraph
                                                                    text={product.items[itemIndex].configuration.fineline.selected.value.product.stockItemCd}
                                                                    isCopyable={true}
                                                                    innerTag={"strong"}
                                                                    innerTagColour={""}
                                                                    outerTag={"div"}
                                                                />
                                                                <Paragraph
                                                                    text={product.items[itemIndex].configuration.fineline.selected.value.product.prodCode}
                                                                    isCopyable={true}
                                                                    innerTag={"strong"}
                                                                    innerTagColour={""}
                                                                    outerTag={"div"}
                                                                />
                                                            </div>
                                                    }
                                                    <small>
                                                        {product.items[itemIndex].configuration.fineline.selected.value.product.description}
                                                    </small>
                                                    {/*<Paragraph
                                                        text={product.items[itemIndex].configuration.fineline.selected.value.product.soldOut ? "Sold Out" : ""}
                                                        isCopyable={false}
                                                        innerTag={"badge"}
                                                        innerTagColour={"danger"}
                                                        outerTag={"div"}
                                                    />*/}
                                                {
                                                    item.configuration.fineline.formError.isValid
                                                        ? null
                                                        : <div>
                                                            <Badge color={"danger"}>
                                                                {item.configuration.fineline.formError.message}
                                                            </Badge>
                                                        </div>
                                                }
                                            </td>
                                            {!isHiddenExtendedColumn ?
                                                <td className="align-middle text-center">
                                                    <Paragraph
                                                        text={product.items[itemIndex].configuration.offRange.selected.value.prodCode}
                                                        isCopyable={true}
                                                        innerTag={"strong"}
                                                        innerTagColour={""}
                                                        outerTag={"div"}
                                                    />
                                                </td>
                                                : null
                                            }
                                            {!isHiddenExtendedColumn ?
                                                    <td className="align-middle text-center">
                                                        <Row className="align-middle text-center justify-content-center">
                                                            <strong>$</strong>
                                                            <Paragraph
                                                                text={product.items[itemIndex].configuration.offRange.selected.value.price ?
                                                                    product.items[itemIndex].configuration.offRange.selected.value.price.toFixed(2)
                                                                    : ''}
                                                                isCopyable={true}
                                                                innerTag={"strong"}
                                                                innerTagColour={""}
                                                                outerTag={"div"}
                                                            />
                                                        </Row>
                                                </td>
                                                : null
                                            }
                                            <td className="align-middle text-center">
                                                {
                                                    product.items[itemIndex].configuration.fineline.selected.value.cutFee.stockItemCd
                                                        ? <Paragraph
                                                            text={product.items[itemIndex].configuration.fineline.selected.value.cutFee.stockItemCd}
                                                            isCopyable={true}
                                                            innerTag={"strong"}
                                                            innerTagColour={""}
                                                            outerTag={"div"}
                                                        />
                                                        : "N/A"
                                                }
                                            </td>
                                            {
                                                !currentUser.isExternalUser
                                                    ? <td className="align-middle text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.unitPrice)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true} />
                                                    </td>
                                                    : null
                                            }
                                            {
                                                !currentUser.isExternalUser
                                                    ? <td className="align-middle text-center">
                                                        <NumberFormat
                                                            prefix={'$'}
                                                            value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true} />
                                                    </td>
                                                    : null
                                            }

                                                <td className="align-middle text-center">
                                                    <Button color={"link"}
                                                            title={"click here to copy item"}
                                                            onClick={() => this.handleItemAction("clone", itemIndex)}>
                                                        <i className="fa fa-clone fa-lg"
                                                           aria-hidden="true"/>
                                                    </Button>
                                                    <span className={"text-muted"}>|</span>
                                                    <Button color={"link"}
                                                            title={"click here to delete"}
                                                            onClick={() => this.handleItemAction("delete", itemIndex)}>
                                                        <i className="fa fa-trash-o fa-lg text-danger"
                                                           aria-hidden="true"/>
                                                    </Button>
                                                </td>
                                                {
                                                    !currentUser.isExternalUser
                                                        ? <td className="align-middle text-center">
                                                            <Button color={"link"}
                                                                    title={"click here to open BOM"}
                                                                    onClick={() => this.toggleBOMModal(true, itemIndex)}>
                                                                BOM
                                                            </Button>
                                                        </td>
                                                        : null

                                                }

                                            </tr>
                                        }
                                    )
                                }
                                {
                                    (!currentUser.isExternalUser && items.length > 0)
                                        ? <tr>
                                            <td className="align-middle" colSpan={10}>Total price</td>
                                            <td className="align-middle text-center">
                                                <NumberFormat
                                                    prefix={'$'}
                                                    value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, product.pricing.price)}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}/>
                                            </td>
                                            <td className="align-middle text-center" colSpan={2}/>

                                        </tr>
                                        : null
                                }


                                </tbody>
                            </Table>
                            {
                                workingBOMModal.isOpen
                                    ? <RollerBlindSingleBOMModal
                                        order={order}
                                        itemIndex={workingBOMModal.itemIndex}
                                        product={product}
                                        isOpen={workingBOMModal.isOpen}
                                        toggle={this.toggleBOMModal}
                                        convertToCustom={(stocksByProdCode) => this.handleItemAction("convertToCustom", workingBOMModal.itemIndex, -1, stocksByProdCode)}
                                    />
                                    : null
                            }
                            {
                                isOpenConsolidatedBOMModal
                                    ? <RollerBlindSingleConsolidatedBOMModal
                                        order={order}
                                        product={product}
                                        isOpen={isOpenConsolidatedBOMModal}
                                        toggle={this.toggleConsolidatedBOMModal}/>
                                    : null
                            }
                        </div>
                        :
                        null

                    }

                <Row className={"d-flex align-center"}>
                    <Button
                        color={"primary"}
                        className={"mb-2 ml-1"}
                        onClick={() => this.handleItemAction("new", items.length)}>
                        <i className="fa fa-cart-plus mr-2" aria-hidden="true"/>
                        Add new item
                    </Button>
                    <span className="text-primary ml-4 mt-2 font-weight-bold">{currentUser.footerNote}</span>
                    {currentUser.isExternalUser && (items && items.length > 0) ?
                        <Button color={"primary"}
                            size={"sm"}
                            className={"mb-2 mr-1 ml-auto text-right"}
                            outline={isHiddenExtendedColumn}
                            onClick={() => this.toggleExtendedColumn(!isHiddenExtendedColumn)}>
                            <i className={classnames("fa", "mr-2", {
                                "fa-eye": isHiddenExtendedColumn,
                                "fa-eye-slash": !isHiddenExtendedColumn,
                            })} aria-hidden="true" />
                            {isHiddenExtendedColumn ? "Show Off Range Detail" : "Hide Off Range Detail"}
                        </Button>
                        : null
                    }
                </Row>
            </div>
        </div>
        )
    }
};
